import React, {useContext, useEffect, useState} from 'react';
import {
    BackButton,
    BackContainer,
    Button,
    Cart,
    CartContainer, CheckboxContainer, CheckboxesContainer, CheckboxLabel, CloseInpost, CloseText,
    Container,
    Details,
    DetailsContainer,
    DetailsInput,
    DetailsLabel,
    DetailsSubHeader,
    DetailsTextarea,
    DetailsTitle,
    DiffSummary, EmptyCart, EmptyContainer,
    Header, HiddenCheckbox, InPostContainer,
    Invisible,
    Item,
    ItemDelete,
    ItemDetails,
    ItemImage,
    ItemLeftSide,
    ItemRightSide,
    Items,
    Line,
    Minus,
    Plus,
    Price,
    Quantity,
    QuantityText,
    ShippingContainer, ShippingOptionsContainer, StyledCheckbox,
    Summary,
    TextContainer,
    TextFour,
    TextOne,
    TextThree,
    TextTwo
} from "./Cart.styles";
import {ReactComponent as Bag} from "../../assets/Bag.svg";
import {ReactComponent as Lock} from "../../assets/lock.svg";
import {ReactComponent as Trash} from "../../assets/trash.svg";
import {ReactComponent as Back} from "../../assets/back.svg";
import {UserContext} from "../../utils/UserContext";
import Select, {components} from "react-select";

const styles = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #282828',
        borderRadius:'100px',
        boxShadow: 'none', // Remove box shadow
        fontSize: '16px',
        fontFamily:'var(--fontOther)',
        fontWeight:'600',
        padding:'10px 15px'
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    indicatorSeparator: () => ({
        display: 'none', // Hide the indicator separator
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: 'all .2s ease', // Smooth transition for hover effects
        '&:hover': {
            color: 'black' // Change color when hovering
        },
        padding: 4, // Adjusting padding to match your design
    }),
    menu: (provided) => ({
        ...provided,
        // You can add custom styles for the dropdown menu here if needed
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px'
        // Adjust the option styling as needed
    }),
}
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg height="20" width="20" viewBox="0 0 20 20">
                <path fill="black" d="M5 8L10 13 15 8z"/>
            </svg>
        </components.DropdownIndicator>
    );
};
const Checkbox = ({ className, checked, onChange, ...props }) => {
    const handleChange = (e) => {
        onChange(e);
        console.log("Checkbox zmieniony", e.target.checked);
    };

    return (
        <CheckboxContainer className={className}>
            <HiddenCheckbox checked={checked} onChange={handleChange} {...props}/>
            <StyledCheckbox checked={checked} />
        </CheckboxContainer>
    );
};
const CartItem = ({item, idx}) => {
    const [quantity, setQuantity] = useState(item.quantity);
    const {cart, setCart} = useContext(UserContext)
    const updateQuantityInCart = (increment) => {
        if (idx >= 0 && idx < cart.length) {
            let updatedCart = [...cart];

            if (increment) {
                // Zwiększanie quantity
                updatedCart[idx].quantity += 1;
                let int = quantity;
                int++;
                setQuantity(int);
            } else {
                if (quantity === 0) {
                    return null;
                } else {
                    let int = quantity;
                    int--;
                    setQuantity(int);
                }
                // Zmniejszanie quantity, ale nie poniżej 0
                updatedCart[idx].quantity = Math.max(0, updatedCart[idx].quantity - 1);
            }

            updatedCart[idx].cost = updatedCart[idx].quantity * updatedCart[idx].price;

            setCart(updatedCart);

            // Zapisywanie zmienionego koszyka w localStorage
            localStorage.setItem('photoCart', JSON.stringify(updatedCart));
        } else {
            console.log("Nie znaleziono produktu o podanym indeksie.");
        }
    }
    const removeFromCart = () => {
        if (idx >= 0 && idx < cart.length) {
            let updatedCart = cart.filter((item, index) => index !== idx);

            setCart(updatedCart);

            // Zapisywanie zmienionego koszyka w localStorage
            localStorage.setItem('photoCart', JSON.stringify(updatedCart));
        } else {
            console.log("Nie znaleziono produktu o podanym indeksie.");
        }
    }
    return (
        <Item>
            <ItemLeftSide>
                <ItemImage image={item.image}/>
                <ItemDetails>
                    <DetailsTitle>{item.type}{' '}{item.size}cm</DetailsTitle>
                    <DetailsSubHeader>{item.paper !== null ? item.paper.label : null}</DetailsSubHeader>
                    <ItemDelete onClick={removeFromCart}> <Trash/> Usuń</ItemDelete>
                </ItemDetails>
            </ItemLeftSide>
            <ItemRightSide>
                <Quantity>
                    <Minus onClick={() => updateQuantityInCart(false)}>-</Minus>
                    <QuantityText>{quantity}</QuantityText>
                    <Plus onClick={() => updateQuantityInCart(true)}>+</Plus>
                </Quantity>
                <Price>
                    {item.price * quantity}zł
                </Price>
            </ItemRightSide>
        </Item>
    )
}
const Checkout = () => {
    const {cart, setCart} = useContext(UserContext);
    const [showMethods, setShowMethods] = useState(false);
    const [shipping, setShipping] = useState({
        personal:false,
        courier:true,
        inpost:false
    });
    const [parcelLocker, setParcelLocker] = useState(null);
    const [showWidget, setShowWidget] = useState(false);
    useEffect(() => {
       const script = document.createElement('script');
       script.src = 'https://geowidget.inpost.pl/inpost-geowidget.js';
       script.async = true;
       document.body.appendChild(script);
       document.addEventListener('onpointselect', (event) =>{
           setParcelLocker(event.detail)
           setShowWidget(false)
       });
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <Cart>
            <Container>
                {
                    !showMethods && cart.length > 0 ?
                        <>
                            <Header>
                                <Bag style={{marginRight:'20px'}} />
                                Twój koszyk
                            </Header>
                            <CartContainer>
                                <Items>
                                    {
                                        cart.map((item,idx) => {
                                            return (
                                                <CartItem item={item} key={idx} idx={idx}/>
                                            )
                                        })
                                    }
                                </Items>
                                <Summary>
                                    <TextOne>Podsumowanie</TextOne>
                                    <TextContainer>
                                        <TextTwo>Zamówienie</TextTwo>
                                        <TextTwo>   {cart.reduce((total, photo) => {
                                            return total + photo.cost;
                                        }, 0)}zł</TextTwo>
                                    </TextContainer>
                                    <TextContainer>
                                        <TextTwo>Dostawa</TextTwo>
                                        <TextTwo>{cart.reduce((total, photo) => {
                                            return total + photo.cost;
                                        }, 0) > 50 ? "Darmowa" : shipping.personal ? "0zł" : "15zł"}</TextTwo>
                                    </TextContainer>
                                    <ShippingOptionsContainer>
                                        <CheckboxesContainer>
                                            <Checkbox
                                                checked={shipping.personal}
                                                onChange={() => {
                                                    setShipping({ ...shipping, personal: true, courier: false, inpost: false });

                                                }}
                                            />

                                            <CheckboxLabel>Odbiór osobisty</CheckboxLabel>
                                        </CheckboxesContainer>
                                        <CheckboxesContainer>
                                            <Checkbox
                                                checked={shipping.courier}
                                                onChange={() => {
                                                    // Create a new object with the updated properties
                                                    setShipping({ ...shipping, personal: false, courier: true, inpost: false });
                                                }}
                                            />
                                            <CheckboxLabel>Kurier (15zł)</CheckboxLabel>
                                        </CheckboxesContainer>
                                        <CheckboxesContainer>
                                            <Checkbox
                                                checked={shipping.inpost}
                                                onChange={() => {
                                                    setShipping(prevState => ({
                                                        ...prevState,
                                                        personal: !prevState.personal,
                                                        courier: false,
                                                        inpost: false
                                                    }));
                                                    // Create a new object with the updated properties
                                                    setShipping({ ...shipping, personal: false, courier: false, inpost: true });
                                                }}
                                            />
                                            <CheckboxLabel>Paczkomat (15zł)</CheckboxLabel>
                                        </CheckboxesContainer>
                                    </ShippingOptionsContainer>
                                    <Line />
                                    <TextContainer style={{padding:'0'}}>
                                        <TextThree>
                                            Do zapłaty
                                        </TextThree>
                                        <TextThree>
                                            {cart.reduce((total, photo) => {
                                                return total + photo.cost;
                                            }, 0) > 50 ? `${cart.reduce((total, photo) => {
                                                return total + photo.cost;
                                            }, 0)}zł` : shipping.personal ? `${cart.reduce((total, photo) => {
                                                return total + photo.cost;
                                            }, 0)}zł` : `${cart.reduce((total, photo) => {
                                                return total + photo.cost;
                                            }, 0) + 15}zł`}
                                        </TextThree>
                                    </TextContainer>
                                    <TextFour>(w tym VAT)</TextFour>
                                    <Button onClick={()=> setShowMethods(true)}>
                                        <Lock />
                                        Wybierz metode dostawy
                                        <Invisible />
                                    </Button>
                                </Summary>
                            </CartContainer>
                        </>
                        : showMethods && cart.length > 0 ?
                            <ShippingContainer>
                                <BackContainer>
                                    <BackButton onClick={()=>setShowMethods(false)}>
                                        <Back style={{marginRight:'20px'}} />
                                        Wróć do koszyka
                                    </BackButton>
                                </BackContainer>
                                <DetailsContainer>
                                    <InPostContainer show={showWidget}>
                                        <CloseInpost>
                                            <CloseText onClick={()=>setShowWidget(false)}>Zamknij</CloseText>
                                        </CloseInpost>
                                        <inpost-geowidget
                                            onpoint="onpointselect"
                                            style={{width:'100%',height:'100%'}}
                                            token='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMTcyMzgxNjgsImlhdCI6MTcwMTg3ODE2OCwianRpIjoiNGRlN2Q4NDMtOGI0YS00MDVjLTk5OWEtY2IyYzg1M2Y0MmU0IiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpXMjRHM2d6VWh3VDF2QXl3eElJT3pKLTRxaFE2RVpTRnBNVXhKMUtnQnE4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiMDFjMTJhNmUtY2JlMS00M2IxLTkxNmMtM2JkZTEwYjZjZjQ0Iiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjAxYzEyYTZlLWNiZTEtNDNiMS05MTZjLTNiZGUxMGI2Y2Y0NCIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6ImMxNjY4NGQ2LTUyMTQtNDA5NC1hYzQ3LTBkZWNmZWJkODVjMiJ9.QKEtv77Ms19pS5LLWCLSZawOAmlejTE2KeLvLsbWQWgFMlhRHNrQxQXgrTX8FBkMvobqsxGY3dlkiF6m8nMOr0u4Lp0V9_oSRhby8eRDb5w2KmVdrHJqhOSD2OUw5w7aQISSOamq3MvgUHvrNhau57DQ-kZy1lHkaoIxpzsPKmxQsbdu17ohFnUu7ijb2VfyYOmtii7DnCMJ8pMLDveq9SJ7Dis5eyAUW7jnPwTie4O3nEljCx0JZ4laJiTbsl3CMipZWOs3pE7fZQ0GakMHWZeFwShmdNj273TVvEJ4yMEYCuX-VRXYkAfhHjOuDkneGdE2U_CFNQVxAH6hGFZSag'
                                            language='pl' config='parcelcollect'></inpost-geowidget>
                                    </InPostContainer>
                                    <Details>
                                        {
                                            shipping.inpost ?
                                                <DetailsInput
                                                    type="text"
                                                    placeholder="Wybierz paczkomat"
                                                    value={parcelLocker ? `Paczkomat: ${parcelLocker.name}` : ""}
                                                    onClick={() => setShowWidget(true)}
                                                />
                                                : null
                                        }
                                        {
                                            shipping.courier
                                                ?
                                                <>
                                                    <DetailsLabel>Adres</DetailsLabel>
                                                    <DetailsInput type={"text"} placeholder={"Ulica i numer domu"}/>
                                                    <DetailsLabel>Miejscowość</DetailsLabel>
                                                    <DetailsInput type={"text"} placeholder={"Miejscowość"}/>
                                                    <DetailsLabel>Kod pocztowy</DetailsLabel>
                                                    <DetailsInput type={"text"} placeholder={"np. 44-194"}/>
                                                </>
                                                : null
                                        }
                                        <DetailsLabel>Imie i nazwisko</DetailsLabel>
                                        <DetailsInput type={"text"} placeholder={"Imie i nazwisko adresata"}/>
                                        <DetailsLabel>Adres email</DetailsLabel>
                                        <DetailsInput type={"text"} placeholder={"Adres email do powiadomień o statusie"}/>
                                        <DetailsLabel>Numer telefonu</DetailsLabel>
                                        <DetailsInput type={"number"} placeholder={"Numer telefonu do kontatku z kurierem"}/>
                                        <DetailsLabel>Uwagi</DetailsLabel>
                                        <DetailsTextarea/>
                                        <DiffSummary>
                                            <TextContainer>
                                                <TextTwo>Zamówienie</TextTwo>
                                                <TextTwo>   {cart.reduce((total, photo) => {
                                                    return total + photo.cost;
                                                }, 0)}zł</TextTwo>
                                            </TextContainer>
                                            <TextContainer>
                                                <TextTwo>Dostawa</TextTwo>
                                                <TextTwo>{cart.reduce((total, photo) => {
                                                        return total + photo.cost;
                                                    }, 0) > 50 ? "Darmowa" : shipping.personal ? "0zł" : "15zł"}</TextTwo>
                                            </TextContainer>
                                            <Line />
                                            <TextContainer style={{padding:'0'}}>
                                                <TextThree>
                                                    Do zapłaty
                                                </TextThree>
                                                <TextThree>
                                                    {cart.reduce((total, photo) => {
                                                        return total + photo.cost;
                                                    }, 0) > 50 ? `${cart.reduce((total, photo) => {
                                                            return total + photo.cost;
                                                        }, 0)}zł` : shipping.personal ? `${cart.reduce((total, photo) => {
                                                            return total + photo.cost;
                                                        }, 0)}zł` : `${cart.reduce((total, photo) => {
                                                            return total + photo.cost;
                                                        }, 0) + 15}zł`}
                                                </TextThree>
                                            </TextContainer>
                                            <TextFour>(w tym VAT)</TextFour>
                                            <Button>
                                                <Lock />
                                                Przejdź do płatności
                                                <Invisible />
                                            </Button>
                                        </DiffSummary>
                                    </Details>
                                </DetailsContainer>

                            </ShippingContainer>
                        :
                        <EmptyContainer>
                            <EmptyCart>
                                Twój koszyk jest pusty
                            </EmptyCart>
                        </EmptyContainer>
                }
            </Container>
        </Cart>
    );
};

export default Checkout;
