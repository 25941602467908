import { authHandler } from './authHandler';
import { handleResponse } from './handleResponse';

export const refreshToken = async (oldUrl, oldRequestOptions,setUser, navigate) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({refresh: JSON.parse(window.sessionStorage.getItem('refresh'))})
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/auth/token/refresh/`, requestOptions)
        .then(r => r.json())
        .then(data => {
            if(data.access) {
                sessionStorage.setItem('access', JSON.stringify(data.access));
                sessionStorage.setItem("user",JSON.stringify({user_id:data.user_id,user_profile_id:data.user_profile_id,photographer_profile_id:data.photographer_profile_id}))
                if (oldRequestOptions.headers && oldRequestOptions.headers.Authorization) {
                    oldRequestOptions.headers.Authorization = authHandler(setUser)
                }
                return fetch(oldUrl, oldRequestOptions).then().then(r => handleResponse(r, oldRequestOptions,setUser));
            } else if(data.detail === 'Token is invalid or expired'){
                return logOut(setUser, navigate);
            }
            return data;
        });
};
export const logOut = (setUser, navigate) => {
    sessionStorage.removeItem('refresh');
    sessionStorage.removeItem('access');
    setUser(null)
    navigate('/');
};