import React, {useContext, useState} from 'react';
import {
    Button,
    ButtonsContainer,
    Input,
    InputsContainer,
    Label,
    Modal,
    ModalContainer,
    MyEditHeader
} from "./EditModal.styles";
import {authHandler} from "../../../../utils/authHandler";
import {UserContext} from "../../../../utils/UserContext";
import {useNavigate} from "react-router-dom";
import {handleResponse} from "../../../../utils/handleResponse";

const EditModal = ({editEmail, editPassword, editShipping, addShipping, setShowModal, setProfile, profile, address}) => {
    const handleCloseModal = () => {
        setShowModal(false);
    }
    const {user,setUser} = useContext(UserContext);
    const navigate = useNavigate()
    const [modalValues,setModalValues] = useState({
        email:"",
        oldpass:"",
        newpass:"",
        city:address &&  address.city !== null ? address.city : "",
        postal:address &&  address.postcode !== null ? address.postcode : "",
        street:address &&  address.street !== null ? address.street : "",
        street_number:address &&  address.street_number !== null ? address.street_number : "",
        apartment_number:(address &&  address.apartment_number) !== null ? address.apartment_number : "",
        first_name:address &&  address.first_name !== null ? address.first_name : "",
        last_name:address &&  address.last_name !== null ? address.last_name : "",
    })
    const handleChangeModalValue = (e) =>{
        setModalValues(inputs => ({...inputs, [e.target.name] : e.target.value}))
    }
    const handleEditEmail = async() =>{
        const auth = authHandler();
        const requestOptions = {
            method:'PATCH',
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
            body:JSON.stringify({user:{email:modalValues.email}})
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/user-profile/${user.user_profile_id}/`,requestOptions)
            .then(response => handleResponse(response,requestOptions,setUser,navigate))
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(err => console.error(err));
    }
    const handleEditPassword = async() =>{
        const auth = authHandler();
        const requestOptions = {
            method:"POST",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
            body:JSON.stringify({
                uid:user.user_id,
                token:user.access,
                new_password:modalValues.newpass
            })
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/auth/confirm-password-reset/`,requestOptions)
            .then(response => handleResponse(response,requestOptions,setUser,navigate))
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(err => console.error(err));
    }
    const handleEditShipping = async() =>{
        const auth = authHandler();
        const requestOptions = {
            method:"PATCH",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
            body:JSON.stringify({
                first_name:modalValues.first_name,
                last_name:modalValues.last_name,
                city:modalValues.city,
                postcode:modalValues.postal,
                street:modalValues.street,
                street_number:modalValues.street_number,
                apartment_number:modalValues.apartment_number,
            })
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/shipping-details/${address.id}/`,requestOptions)
            .then(response => handleResponse(response,requestOptions,setUser,navigate))
            .then(response => response.json())
            .then(data => {
                let temp = profile;
                temp.shipping_details[temp.shipping_details.indexOf(address)] = {
                    id:address.id,
                    first_name:modalValues.first_name,
                    last_name:modalValues.last_name,
                    city:modalValues.city,
                    postcode:modalValues.postal,
                    street:modalValues.street,
                    street_number:modalValues.street_number,
                    apartment_number:modalValues.apartment_number,}
                setProfile(temp);
                handleCloseModal()
            } )
            .catch(err => console.error(err));
    }
    const handleDeleteShipping = async () => {
        const auth = authHandler();
        const requestOptions = {
            method:'DELETE',
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/shipping-details/${address.id}`,requestOptions)
            .then(response => handleResponse(response,requestOptions,setUser,navigate))
            .then(data => {
                let temp = profile;
                temp.shipping_details = temp.shipping_details.filter(el=>{
                    return el !== temp.shipping_details[temp.shipping_details.indexOf(address)]
                })
                setProfile(temp);
                handleCloseModal()
            } )
            .catch(err => console.error(err));
    }
    const handleAddShipping = async() =>{
        const auth = authHandler();
        const requestOptions = {
            method:"POST",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
            body:JSON.stringify({
                first_name:modalValues.first_name,
                last_name:modalValues.last_name,
                city:modalValues.city,
                postcode:modalValues.postal,
                street:modalValues.street,
                street_number:modalValues.street_number,
                apartment_number:modalValues.apartment_number,
            })
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/shipping-details/`,requestOptions)
            .then(response => handleResponse(response,requestOptions,setUser,navigate))
            .then(response => response.json())
            .then(data => {
                let temp = profile;
                temp.shipping_details.push(data);
                setProfile(temp);
                handleCloseModal()
            } )
            .catch(err => console.error(err));
    }
    return (
        <ModalContainer>
            <Modal>
                    {editEmail ?
                        <MyEditHeader>
                            zmień email
                            <span className="material-symbols-outlined">alternate_email</span>
                        </MyEditHeader>
                        : editPassword ?
                            <MyEditHeader>
                                zmień hasło
                                <span className="material-symbols-outlined">lock</span>
                            </MyEditHeader>
                            : editShipping ?
                                <MyEditHeader>
                                    zmień dane
                                    <span className="material-symbols-outlined">local_shipping</span>
                                </MyEditHeader>
                                : addShipping ?
                                    <MyEditHeader>
                                        dodaj dane
                                        <span className="material-symbols-outlined">edit</span>
                                    </MyEditHeader>
                                    : null}

                {editEmail
                    ?
                        <InputsContainer>
                            <Label>Nowy Email:</Label>
                            <Input type="email" required name="email" onChange={handleChangeModalValue} value={modalValues.email} />
                        </InputsContainer>
                    :
                        null
                }
                {editPassword
                    ?
                    <InputsContainer>
                        <Label>Stare hasło:</Label>
                        <Input type="password" required name="oldpass" onChange={handleChangeModalValue} value={modalValues.oldpass}/>
                        <Label>Nowe hasło:</Label>
                        <Input type="password" required name="newpass" onChange={handleChangeModalValue} value={modalValues.newpass}/>
                    </InputsContainer>
                    :
                    null
                }
                {editShipping
                    ?
                    <InputsContainer>
                        <Label>Imię:</Label>
                        <Input type="text" required name="first_name" onChange={handleChangeModalValue} value={modalValues.first_name}/>
                        <Label>Nazwisko:</Label>
                        <Input type="text" required name="last_name" onChange={handleChangeModalValue} value={modalValues.last_name}/>
                        <Label>Miasto:</Label>
                        <Input type="text" required name="city" onChange={handleChangeModalValue} value={modalValues.city}/>
                        <Label>Kod pocztowy:</Label>
                        <Input type="text" required name="postal" onChange={handleChangeModalValue} value={modalValues.postal}/>
                        <Label>Ulica:</Label>
                        <Input type="text" required name="street" onChange={handleChangeModalValue} value={modalValues.street}/>
                        <Label>Numer ulicy:</Label>
                        <Input type="text" required name="street_number" onChange={handleChangeModalValue} value={modalValues.street_number}/>
                        <Label>Numer mieszkania/domu:</Label>
                        <Input type="text" required name="apartment_number" onChange={handleChangeModalValue} value={modalValues.apartment_number}/>
                    </InputsContainer>
                    :
                    null
                }
                {addShipping
                    ?
                    <InputsContainer>
                        <Label>Imię:</Label>
                        <Input type="text" required name="first_name" onChange={handleChangeModalValue} value={modalValues.first_name}/>
                        <Label>Nazwisko:</Label>
                        <Input type="text" required name="last_name" onChange={handleChangeModalValue} value={modalValues.last_name}/>
                        <Label>Miasto:</Label>
                        <Input type="text" required name="city" onChange={handleChangeModalValue} value={modalValues.city}/>
                        <Label>Kod pocztowy:</Label>
                        <Input type="text" required name="postal" onChange={handleChangeModalValue} value={modalValues.postal}/>
                        <Label>Ulica:</Label>
                        <Input type="text" required name="street" onChange={handleChangeModalValue} value={modalValues.street}/>
                        <Label>Numer ulicy:</Label>
                        <Input type="text" required name="street_number" onChange={handleChangeModalValue} value={modalValues.street_number}/>
                        <Label>Numer mieszkania/domu:</Label>
                        <Input type="text" required name="apartment_number" onChange={handleChangeModalValue} value={modalValues.apartment_number}/>
                    </InputsContainer>
                    :
                    null
                }
                <ButtonsContainer>
                    {editShipping ? <Button onClick={handleDeleteShipping}>usuń <span
                        className="material-symbols-outlined">
delete
</span></Button> : null}
                    <Button onClick={editEmail ? handleEditEmail : editPassword ? handleEditPassword : editShipping ? handleEditShipping : addShipping ? handleAddShipping : null}>
                        {addShipping ? "dodaj" : "edytuj"}{addShipping ? <span className="material-symbols-outlined">add</span> : <span className="material-symbols-outlined">edit</span>}
                    </Button>
                    <Button onClick={handleCloseModal}>zamknij <span className="material-symbols-outlined">close</span></Button>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};

export default EditModal;