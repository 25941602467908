import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {authHandler} from "../../utils/authHandler";
import {handleResponse} from "../../utils/handleResponse";
import {UserContext} from "../../utils/UserContext";
import {
    AddToCart,
    AnimatedGalleryPhoto,
    BagText,
    Blur,
    BuyPhoto,
    CartContainer,
    CodeBar,
    CodeBarContainer,
    Container,
    FirstContainer,
    FreeShipping,
    FullscreenBackground,
    GalleryPhotoContainer,
    Minus,
    MobileCloseContainer,
    OpacityBackground,
    OpacityInteraction,
    OpacityOptions,
    Option,
    Options,
    Overlay,
    OverlayHolder,
    PhotofolderContainer,
    PhotofolderHeader,
    PhotoInteraction,
    PhotoOptions,
    PhotosContainer,
    PhotoThumbnail,
    Plus,
    Quantity,
    QuantityText,
    SecondContainer,
    Sum,
    SumBold,
    Thumbnail,
    Variant,
    Variants,
    VariantSize,
    VariantsTitle,
    ViewCart,
    YourCode
} from "./Photofolder.styles";
import {ReactComponent as Close} from "../../assets/closebutton.svg";
import {ReactComponent as Bag} from "../../assets/Bag.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select, {components} from "react-select";

const styles = {
    control: (provided) => ({
        ...provided,
        border: 0, // Remove border
        boxShadow: 'none', // Remove box shadow
        fontSize: '14px',
        '&:hover': {
            border: 0 // Ensure border is removed on hover as well
        }
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    indicatorSeparator: () => ({
        display: 'none', // Hide the indicator separator
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: 'all .2s ease', // Smooth transition for hover effects
        '&:hover': {
            color: 'black' // Change color when hovering
        },
        padding: 4, // Adjusting padding to match your design
    }),
    menu: (provided) => ({
        ...provided,
        // You can add custom styles for the dropdown menu here if needed
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px'
        // Adjust the option styling as needed
    }),
}
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg height="20" width="20" viewBox="0 0 20 20">
                <path fill="black" d="M5 8L10 13 15 8z"/>
            </svg>
        </components.DropdownIndicator>
    );
};
const PhotoVariant = ({size, price, type, image, localCart, setLocalCart}) => {
    const {cart, setCart} = useContext(UserContext);
    const [quantity, setQuantity] = useState(0);
    const [paper, setPaper] = useState({
        value: 0,
        label: "Papier matowy"
    });
    useEffect(() => {
        setQuantity(0);
    }, [type, image]);
    const handleIncrementQuantity = (increment) => {
        if (increment) {
            let int = quantity;
            int++;
            setQuantity(int);
        } else {
            if (quantity === 0) {
                return null;
            } else {
                let int = quantity;
                int--;
                setQuantity(int);
            }
        }
    }
    const handleAddToCart = () => {;
        // Sprawdzanie, czy quantity jest większe od 0
        if (quantity > 0) {
            let newItem = {
                size,
                quantity,
                price,
                type,
                paper: type === "Odbitka" ? paper : null,
                cost: quantity * price,
                image,
            };

            let newCart = [...cart];
            let existingItemIndex = newCart.findIndex(item =>
                item.size === newItem.size &&
                item.type === newItem.type &&
                item.paper === newItem.paper &&
                item.image === newItem.image
            );

            if (existingItemIndex !== -1) {
                // Element już istnieje, aktualizujemy tylko quantity i cost
                newCart[existingItemIndex].quantity += newItem.quantity;
                newCart[existingItemIndex].cost += newItem.cost;
            } else {
                // Dodajemy nowy element
                newCart.push(newItem);
            }

            setCart(newCart);
            setLocalCart(newCart);
            setQuantity(0);

            // Zapisywanie koszyka w localStorage
            localStorage.setItem('photoCart', JSON.stringify(newCart));
        } else {
            console.log("Ilość jest równa 0, nie dodajemy do koszyka.");
        }
    }

    return (
        type === "Odbitka" ?
            <Variant>
                <FirstContainer>
                    <VariantSize>{size}cm</VariantSize>
                    <Quantity>
                        <Minus onClick={() => handleIncrementQuantity(false)}>-</Minus>
                        <QuantityText>{quantity}</QuantityText>
                        <Plus onClick={() => handleIncrementQuantity(true)}>+</Plus>
                    </Quantity>
                    <Select
                        components={{DropdownIndicator}}
                        defaultValue={0}
                        value={paper}
                        onChange={(value)=>setPaper(value)}
                        styles={styles}
                        options={[{
                            value: 0,
                            label: "Papier matowy"
                        }]}
                        isSearchable={false}
                    />
                </FirstContainer>
                <SecondContainer>
                    <Sum>
                        Suma:<SumBold>{price * quantity}zł</SumBold>
                    </Sum>
                    <AddToCart onClick={handleAddToCart}>
                        Dodaj do koszyka
                    </AddToCart>
                </SecondContainer>
            </Variant>
            :
            <Variant>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <VariantSize style={{marginRight: '30px'}}>{size}cm</VariantSize>
                    <Quantity>
                        <Minus onClick={() => handleIncrementQuantity(false)}>-</Minus>
                        <QuantityText>{quantity}</QuantityText>
                        <Plus onClick={() => handleIncrementQuantity(true)}>+</Plus>
                    </Quantity>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Sum>
                        Suma:<SumBold>{price * quantity}zł</SumBold>
                    </Sum>
                    <AddToCart onClick={handleAddToCart}>
                        Dodaj do koszyka
                    </AddToCart>
                </div>
            </Variant>
    )
}
const Photofolder = () => {
    const history = useLocation();
    const navigate = useNavigate();
    const {user, setUser, setFullscreen, cart, setCart} = useContext(UserContext);
    const [code, setCode] = useState(history.state);
    const [folder, setFolder] = useState([]);
    const [photoVersion, setPhotoVersion] = useState(0);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [photoStyle, setPhotoStyle] = useState({});
    const [showFullscreen, setShowFullscreen] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [showOverlay, setShowOverlay] = useState(null);
    const [showBag, setShowBag] = useState(true);
    const [menu, setMenu] = useState("Odbitki");
    const [localCart, setLocalCart] = useState(cart);
    const fetchFolder = async () => {
        if (user !== null && user.access !== null && user.access !== undefined) {
            const auth = authHandler();
            const requestOptions = {
                method: "GET",
                headers: {'Content-Type': 'application/json', 'X-FOLDER-CODE': code, 'Authorization': auth},
            }
            return fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/folder-by-code/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => {
                    const result = response.json();
                    return result;
                })
                .then(data => setFolder(data));
        } else {
            const requestOptions = {
                method: "GET",
                headers: {'Content-Type': 'application/json', 'X-FOLDER-CODE':code},
            }
            return fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/folder-by-code/`, requestOptions)
                .then(response => {
                    if (response.ok) {
                        return response;
                    } else {
                        console.log("Error")
                    }
                })
                .then(response => {
                    const result = response.json();
                    return result;
                })
                .then(data => setFolder(data));
        }
    }
    useEffect(() => {
        fetchFolder();
    }, [])
    const fetchProducts = async () => {
        const requestOptions = {
            method: "GET",
            headers: {'Content-Type': 'application/json', 'X-FOLDER-CODE': history.state,},
        }
        if (user !== null && user.access !== null && user.access !== undefined) {
            return fetch(`${process.env.REACT_APP_API_URL}/api/photos/project/1/products/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => {
                    const result = response.json();
                    return result;
                })
        } else {
            return fetch(`${process.env.REACT_APP_API_URL}/api/photos/project/1/products/`, requestOptions)
                .then(response => {
                    if (response.ok) {
                        return response;
                    }
                })
                .then(response => {
                    const result = response.json();
                    return result;
                })
        }

    }
    const [containerHeight, setContainerHeight] = useState(0);
    const imageRefs = useRef([]);

    const initialWidth = document.documentElement.clientWidth;
    const number = initialWidth >= 1200 ? 5
        : initialWidth < 1200 && initialWidth >= 920 ? 4
            : initialWidth < 920 && initialWidth > 650 ? 3 : 2;
    const columnWidth = initialWidth > 1200 ? 240 : initialWidth / number;
    const [columnHeights, setColumnHeights] = useState(new Array(number).fill().map(() => []));
    const onImageLoad = (idx) => {
        requestAnimationFrame(() => {
            const img = imageRefs.current[idx];
            if (img) {
                const imageHeight = img.offsetHeight + 2; // Dodajemy 2px marginesu
                const column = idx % number;

                setColumnHeights(prevHeights => {
                    // Zapewnienie, że prevHeights jest poprawnie zainicjowane
                    const newHeights = prevHeights.map(col => Array.isArray(col) ? [...col] : []);

                    // Sprawdzenie, czy aktualizacja jest potrzebna
                    if (newHeights[column][idx] !== imageHeight) {
                        // Dodanie nowej wysokości do odpowiedniej kolumny
                        newHeights[column][idx] = imageHeight;

                        // Sprawdzanie, czy wymagana jest aktualizacja containerHeight
                        const totalHeights = newHeights.map(col => col.reduce((sum, h) => sum + (h || 0), 0));
                        const newContainerHeight = Math.max(...totalHeights);
                        if (newContainerHeight + 100 !== containerHeight) {
                            setContainerHeight(newContainerHeight + 100); // Dodaj 100px marginesu
                        }

                        return newHeights;
                    }

                    return prevHeights;
                });
            }
        });
    };

    // Funkcja obliczająca pozycję 'top' dla danego elementu
    const calculateTopPosition = (column, idx) => {
        let topPosition = 0;
        console.log("Wykonałem się")
        // Uwzględnij tylko zdefiniowane wysokości
        if (columnHeights[column]) {
            for (let i = 0; i < idx; i++) {
                if (columnHeights[column][i] !== undefined) {
                    topPosition += columnHeights[column][i];
                }
            }
        }
        return topPosition;
    };

    const handlePhotoClick = (photo, idx) => {
        setShowOverlay(null);
        setFullscreen(true);
        setSelectedPhoto(photo);
        setShowFullscreen(true);
    };
    const topPositions = useMemo(() => {
        // Sprawdź, czy folder.folder_photos jest zdefiniowane
        if (folder && folder.folder_photos) {
            return folder.folder_photos.map((_, idx) => {
                const column = idx % number;
                return calculateTopPosition(column, idx);
            });
        }
        // Jeśli folder.folder_photos nie jest zdefiniowane, zwróć pustą tablicę
        return [];
    }, [folder, number, columnHeights]);

    const GalleryPhotoRef = React.forwardRef((props, ref) => (
        <img ref={ref} {...props} />
    ));
    if (!folder || !folder.folder_photos) {
        return <div>Loading...</div>;
    }
    return (
        <PhotofolderContainer>
            {selectedPhoto && (
                <FullscreenBackground show={showFullscreen}>
                    <PhotoInteraction>
                        <Close style={{cursor: 'pointer'}} onClick={() => {
                            setShowFullscreen(false);
                            setFullscreen(false);
                        }}/>
                        <BuyPhoto onClick={() => setShowOptions(true)}>Kup zdjęcie</BuyPhoto>
                    </PhotoInteraction>
                    <AnimatedGalleryPhoto
                        src={`data:image/png;base64,${selectedPhoto.image}`}
                        show={showFullscreen}
                        startPos={photoStyle.startPos}
                        endPos={photoStyle.endPos}
                    />
                </FullscreenBackground>
            )}
            {showOptions && (
                <OpacityBackground show={showFullscreen}>
                    <OpacityInteraction>
                        <Close style={{cursor: 'pointer'}} onClick={() => {
                            setShowOptions(false);
                        }}/>
                    </OpacityInteraction>
                    <OpacityOptions>
                        <MobileCloseContainer>
                            <Close style={{cursor: 'pointer'}} onClick={() => {
                                setShowOptions(false);
                            }}/>
                        </MobileCloseContainer>
                        <PhotoThumbnail image={`data:image/png;base64,${selectedPhoto.image}`}>
                            <Blur>
                                <Thumbnail src={`data:image/png;base64,${selectedPhoto.image}`}/>
                            </Blur>
                        </PhotoThumbnail>
                        <PhotoOptions>
                            <Options>
                                <Option onClick={() => setMenu("Odbitki")} chosen={menu === "Odbitki"}>Odbitki</Option>
                                <Option onClick={() => setMenu("Kalendarz")}
                                        chosen={menu === "Kalendarz"}>Kalendarz</Option>
                                <Option onClick={() => setMenu("Obraz")} chosen={menu === "Obraz"}>Obraz na
                                    płótnie</Option>
                            </Options>
                            <Variants>
                                {
                                    menu === "Odbitki"
                                        ?
                                            <>
                                                <VariantsTitle>Dostępne formaty dla wybranego zdjęcia.</VariantsTitle>
                                                <PhotoVariant localCart={localCart} setLocalCart={setLocalCart} image={`data:image/png;base64,${selectedPhoto.image}`} type="Odbitka" size={"10 x 15"} price={10}/>
                                                <PhotoVariant localCart={localCart} setLocalCart={setLocalCart}  image={`data:image/png;base64,${selectedPhoto.image}`} type="Odbitka" size={"15 x 23"} price={10}/>
                                            </>
                                        : menu === "Kalendarz"
                                            ?
                                                <>
                                                    <VariantsTitle>Dostępne formaty dla wybranego zdjęcia.</VariantsTitle>
                                                    <PhotoVariant localCart={localCart} setLocalCart={setLocalCart}  image={`data:image/png;base64,${selectedPhoto.image}`} type="Kalendarz" size={"21 x 50"} price={35}/>
                                                </>
                                            :
                                                <>
                                                    <VariantsTitle>Wysokiej jakości wydruk na płótnie, naciągnięty na
                                                        krosno.</VariantsTitle>
                                                    <PhotoVariant localCart={localCart} setLocalCart={setLocalCart}  image={`data:image/png;base64,${selectedPhoto.image}`} type="Obraz na płótnie" size={"20 x 30"} price={60}/>
                                                </>

                                }
                            </Variants>
                            <CartContainer>
                                <FreeShipping>
                                    Darmowa dostawa powyżej 50zł
                                </FreeShipping>
                                <Sum>
                                    Suma:<SumBold>{localCart.reduce((total, photo) => {
                                    return total + photo.cost;
                                }, 0)} zł</SumBold>
                                </Sum>
                                <ViewCart to={"/cart"} onClick={() => setFullscreen(false)}>
                                    Zobacz koszyk ({localCart.length})
                                </ViewCart>
                            </CartContainer>
                        </PhotoOptions>
                    </OpacityOptions>
                </OpacityBackground>
            )}
            <CodeBar>
                <CodeBarContainer>
                    <PhotofolderHeader>Folder przygotowany dla Ciebie</PhotofolderHeader>
                    <YourCode>Twój kod: {code}</YourCode>
                </CodeBarContainer>
            </CodeBar>
            <Container>
                <PhotosContainer style={{height: `${containerHeight}px`}}>
                    {folder.folder_photos.map((folder_element, idx) => {
                        const column = idx % number;
                        const leftPosition = column * (columnWidth + 2);
                        const topPosition = topPositions[idx];
                        return (
                            <GalleryPhotoContainer
                                onClick={(e) => handlePhotoClick(folder_element.photo, idx, e.target)}
                                style={{
                                    left: `${leftPosition}px`,
                                    top: `${topPosition}px`,
                                    // width: `${columnWidth}px`
                                }}
                            >
                                <GalleryPhotoRef
                                    ref={el => imageRefs.current[idx] = el}
                                    key={folder_element.photo.id}
                                    onLoad={() => onImageLoad(idx)}
                                    src={`data:image/png;base64,${folder_element.photo.image}`}
                                    alt={folder_element.photo.image_name}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <OverlayHolder>
                                    <Overlay>
                                       <Bag/>
                                    </Overlay>
                                </OverlayHolder>
                            </GalleryPhotoContainer>
                        );
                    })}
                </PhotosContainer>
            </Container>
        </PhotofolderContainer>
    );
};

export default Photofolder;
