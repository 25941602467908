import styled from 'styled-components';
import { LoginHeader } from '../../Register/Register.styles';
import { Link } from 'react-router-dom';
export const MyAccountPage = styled.section`
  width:100%;
  height:calc(100vh - 300px);
  min-height:800px;
  background-color:white;
  padding-top:70px;
  @media(max-width:500px){
    min-height:0px;
    height:auto;
  }
`
export const Container = styled.div`
  width:100%;
  max-width:1200px;
  margin: 0 auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  padding:50px 0 150px 0;
`
export const MyAccountHeader = styled(LoginHeader)`
  margin:0;
`
export const MyAddresses = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:flex-end;
  width:100%;
  flex-wrap:wrap;
`
export const MyFolders = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  padding:30px 0;
  width:400px;
`
export const MyDetails = styled(MyFolders)`
`
export const MyFoldersHeader = styled(MyAccountHeader)`
  font-size:4rem;
  margin:20px 0;
`
export const MyDetailsHeader = styled(MyFoldersHeader)``
export const MyDataHeader = styled(MyDetailsHeader)``
export const MyFolder = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
`
export const MyDetailsButtons = styled(MyFolder)`
  justify-content: flex-start;
  margin:30px 0;
`
export const MyFolderCode = styled.span`
  font-family:var(--fontOther);
  font-weight:700;
  font-size:2rem;
  color:var(--black);
`
export const MyEmail = styled(MyFolderCode)``
export const MyFolderLink = styled(Link)`
  font-weight:700;
  font-size:2rem;
  display:flex;
  background-color:var(--black);
  align-items:center;
  padding:10px 30px;
  span{
    margin:0 5px;
  }
  color:white;
`
export const MyFolderNotFound = styled(MyFolderCode)``
export const ButtonEdit = styled.button`
  background-color:var(--black);
  outline:none;
  border:none;
  display:flex;
  align-items:center;
  color:white;
  font-weight:700;
  font-size:1.6rem;
  margin-right:15px;
  padding:10px 25px;
`
export const ChangePassword = styled(ButtonEdit)``
export const MyData = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
`
export const MyDataHeaderContainer = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
export const ButtonAdd = styled(ButtonEdit)`
  margin-left:20px;
`
export const MyAddress = styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
`
export const Address = styled.span`
  font-size:2rem;
  font-weight:700;
  font-family:var(--fontOther);
  color:(--black);
`
export const LoaderContainer = styled.div`
  width:100%;
  min-height:600px;
  display:flex;
  align-items:center;
  justify-content:center;
`
export const Loader = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  &::before,&::after{
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(253, 195, 57, 0.75));
  }
  &::before{
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem var(--yellow);
    animation-name: pulsIn;
  }
  &::after{
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0  var(--yellow);
  }
  @keyframes pulsIn {
    0% {
      box-shadow: inset 0 0 0 1rem var(--yellow);
      opacity: 1;
    }
    50%, 100% {
      box-shadow: inset 0 0 0 0 var(--yellow);
      opacity: 0;
    }
  }
  @keyframes pulsOut {
    0%, 50% {
      box-shadow: 0 0 0 0  var(--yellow);
      opacity: 0;
    }
    100% {
      box-shadow: 0 0 0 1rem  var(--yellow);
      opacity: 1;
    }
`
