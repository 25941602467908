import React, {useEffect, useState} from 'react';
import {
    Flag,
    ListedPhotoDate, ListedPhotoDelete,
    ListedPhotoFlag, ListedPhotoInfo,
    ListedPhotoName,
    ListedPhotoNumber, ListedPhotoSize,
    ListedPhotoTest, PhotosListed
} from "../AddFolder.styles";
import flaga from "../../../../../assets/auto_awesome.png";
import {useMutation, useQueryClient} from "react-query";

const PhotoItem = ({photo,handleDeletePhoto,handleChoosePhoto,chosenPhotos, viewFolder}) => {
    const [checked,setChecked] = useState(false);
    const queryClient =  useQueryClient();
    const handleCheckPhoto = () =>{
        handleChoosePhoto(!checked, photo)
        setChecked(!checked);
    }
    useEffect(()=>{
        if(chosenPhotos.length === 0){
            setChecked(false)
        }
    },[chosenPhotos])
    const { mutate, isLoading } = useMutation(handleDeletePhoto, {
        onMutate: async oldFolder => {
            await queryClient.cancelQueries('folder')
            const previousData = queryClient.getQueriesData('folder')
            console.log(oldFolder);
            previousData[0][1].folder_photos.filter(photo => photo.photo.id !== oldFolder)
            console.log(previousData)
            queryClient.setQueryData('folder', previousData[0][1]);
            return { previousData }
        },
        onError: (err,context) =>{
            queryClient.setQueryData('folder', context.previousData)
        },
        onSettled:() => {
            queryClient.invalidateQueries('folder')
        }
    })
    if(isLoading){
        return <div>Loading...</div>
    }
 return (
     <PhotosListed key={photo.photo.id}>
         {!viewFolder
             ?
             <div style={{width: '5%'}}>
                 <input type="checkbox"
                        checked={checked}
                        onChange={handleCheckPhoto}/>
             </div>
             : null}
         <ListedPhotoTest
             style={{backgroundImage: `url('data:image/png;base64,${photo.photo.image}')`}}
             alt=""/>
         <ListedPhotoName>{photo.photo.image_name}</ListedPhotoName>
         <ListedPhotoFlag>{photo.photo.is_processed ?
             <Flag src={flaga}/> : null}{photo.photo.is_highlighted ? <span
             className="material-symbols-outlined">trending_up</span> : null}</ListedPhotoFlag>
         <ListedPhotoDate>{new Date(Date.parse(photo.photo.created_at)).toLocaleDateString("pl-PL")}</ListedPhotoDate>
         <ListedPhotoNumber>{photo.photo.downloads_count}</ListedPhotoNumber>
         <ListedPhotoSize>{photo.photo.image_size} MB</ListedPhotoSize>
         <ListedPhotoDelete
             onClick={viewFolder ? () => mutate(photo.photo.id) : () => handleDeletePhoto(photo.photo.id)}><span
             className="material-symbols-outlined">delete</span></ListedPhotoDelete>
         <ListedPhotoInfo><span className="material-symbols-outlined">info</span></ListedPhotoInfo>
     </PhotosListed>
 );
};

export default PhotoItem;