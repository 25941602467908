import React from 'react';

const Console = () => {
    return (
        <div>
            <h1>Pulpit</h1>
        </div>
    );
};

export default Console;