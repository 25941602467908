import React, { useContext, useState } from 'react';
import {
    Container, ErrorText, Forgotten, Highlight,
    Input,
    InputLabel,
    InputsContainer,
    LoginButton,
    LoginContainer,
    LoginHeader,
    LoginPage, LoginSubHeader, Text
} from './Login.styles';
import arrow from '../../assets/arrow_outward.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../utils/UserContext';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const [login,setLogin] = useState("");
    const [password,setPassword] = useState("");
    const [error,setError] = useState("");
    const {setUser} = useContext(UserContext)
    const navigate = useNavigate()
    const handleChangeLogin = (e) =>{
        setLogin(e.target.value);
    }
    const handleChangePassword = (e) =>{
        setPassword(e.target.value);
    }
    const handleLogin = async (e) =>{
        e.preventDefault();
        const requestOptions = {
            method:"POST",
            headers: {'Content-Type': 'application/json'},
            body:JSON.stringify({email:login,password:password})
        }
       await fetch(`${process.env.REACT_APP_API_URL}/api/users/auth/token/`,requestOptions)
           .then(r => r.json())
           .then(data => {
               if(data.access){
                   setUser(data);
                   sessionStorage.setItem("refresh",JSON.stringify(data.refresh))
                   sessionStorage.setItem("access",JSON.stringify(data.access))
                   sessionStorage.setItem("user",JSON.stringify({user_id:data.user_id,user_profile_id:data.user_profile_id,photographer_profile_id:data.photographer_profile_id}))
                   if(data.user_profile_id !== null){
                       navigate(`/user/my-account`)
                   }else{
                       navigate(`/photographer/my-panel`)
                   }
                   setError("");
               }else{
                   setError(data.detail);
               }
           })
           .catch(err=>console.log(err))
    }
 return (
  <LoginPage>
      <Container>
          <LoginContainer onSubmit={handleLogin}>
              <LoginHeader>Witaj ponownie!</LoginHeader>
              <LoginSubHeader>Zaloguj się ponownie do swojego Folderu.</LoginSubHeader>
              <InputsContainer>
                  <InputLabel>Adres email:</InputLabel>
                  <Input type="text" value={login} onChange={handleChangeLogin} required label="Wpisz adres email"/>
                  <InputLabel>Hasło:</InputLabel>
                  <Input type="password" value={password} onChange={handleChangePassword} required label="Hasło" />
                  <ErrorText>{error}</ErrorText>
                  <Forgotten to={"/reset-password"}>Przypomnij mi hasło</Forgotten>
                  <LoginButton type="submit">
                      Zaloguj się
                  </LoginButton>
                  <Text>Nie masz jeszcze konta? <Highlight to={"/register"}>Utwórz konto</Highlight> </Text>
              </InputsContainer>
          </LoginContainer>
          <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
          />
      </Container>
  </LoginPage>
 );
};

export default Login;
