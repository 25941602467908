import React, {useContext, useState} from 'react';
import {
    Button,
    EasyProfessional,
    Header,
    HeroInputContainer,
    HeroLeft,
    HeroRight,
    HeroSearch,
    HeroSearchButton,
    Text,
    HomeContainer,
    HomeHero,
    IdealMatch,
    Opinion,
    OpinionAvatar,
    OpinionName,
    Opinions,
    OpinionText,
    EasyLeft,
    EasyRight,
    HeroContainer,
    EmptyButton, PinkButton, IdealMatchHeader, IdealMatchText, OpinionHeader, OpinionsText,
} from './Home.styles';
import Avatar from "../../assets/avatar.png";
import {authHandler} from "../../utils/authHandler";
import {handleResponse} from "../../utils/handleResponse";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../utils/UserContext";
import {toast, ToastContainer} from "react-toastify";
import Slider from "react-slick";
import background3 from '../../assets/background3.png'
const Home = () => {
    const [folderCode, setFolderCode] = useState("");
    const navigate = useNavigate();
    const {user,setUser}= useContext(UserContext);
    const handleChangeCode = (e) =>{
        setFolderCode(e.target.value);
    }
    const handleSubmit = async (e) =>{
        if(user !== null && user.access !== null && user.access !== undefined){
            const auth = authHandler(setUser,navigate);
            e.preventDefault();
            const requestOptions = {
                method:"GET",
                headers: {'Content-Type': 'application/json', 'Authorization':auth, 'X-FOLDER-CODE':folderCode},
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photos/check-folder/`,requestOptions)
                .then(r => {
                    return handleResponse(r,requestOptions,setUser,navigate)
                })
                .then(r=>r.json())
                .then(data=>{
                    navigate("/photofolder",{state:data.code})
                })
                .catch(err=>console.log(err))
        }else{
            e.preventDefault();
            const requestOptions = {
                method:"GET",
                headers: {'Content-Type': 'application/json', 'X-FOLDER-CODE':folderCode},
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photos/check-folder/`,requestOptions)
                .then(r=> {
                    if(!r.ok){
                        toast.error('Nie znaleziono folderu z takim kodem',{
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        })
                    }else{
                        return r
                    }
                } )
                .then(r=>r.json())
                .then(data=>{
                    if(data?.detail && data?.detail === "Not found."){
                        toast.error('Nie znaleziono folderu z takim kodem',{
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        })
                    }else{
                        navigate("/photofolder",{state:data.code})
                    }
                })
                .catch(err=>console.log(err))
        }
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };
    return (
        <HomeContainer>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <HomeHero>
                <HeroLeft>
                    <HeroContainer>
                        <Header>
                            Otwórz Folder z udostępnionymi fotografiami
                        </Header>
                        <Text>
                            Wprowadź kod od fotografa, aby uzyskać dostęp do zdjęć:
                        </Text>
                        <HeroInputContainer onSubmit={handleSubmit}>
                            <HeroSearch type="text" onChange={handleChangeCode} placeholder="Wpisz kod do folderu"/>
                            <HeroSearchButton type="submit">Otwórz</HeroSearchButton>
                        </HeroInputContainer>
                    </HeroContainer>
                </HeroLeft>
                <HeroRight />
            </HomeHero>
            <IdealMatch>
                <Header center>
                    Idealne dopasowanie
                </Header>
                <Text center>
                    Niezależnie od tego, czy jesteś fotografem ślubnym, mody, krajobrazowym czy pracujesz w innych dziedzinach, FotoFolder jest wszechstronnym narzędziem dostosowującym się do Twoich potrzeb.
                </Text>
                <EmptyButton>Czytaj więcej</EmptyButton>
            </IdealMatch>
            <EasyProfessional>
                <EasyLeft />
                <EasyRight>
                    <IdealMatchHeader>
                        Łatwe i profesjonalne narzędzie nie tylko dla zawodowych fotografów
                    </IdealMatchHeader>
                    <IdealMatchText>
                        Możesz łatwo zarządzać swoją kolekcją fotografii, oznaczać i katalogować zdjęcia, a także udostępniać w prosty i elegancki sposób.                </IdealMatchText>
                    <PinkButton>Stwórz własny Folder</PinkButton>
                </EasyRight>
            </EasyProfessional>
            <Opinions>
                <OpinionHeader center>
                    Poznaj opinie naszych użytkowników
                </OpinionHeader>
                <OpinionsText center>Zbudowaliśmy FotoFolder z myślą o każdej osobie pasjonującej się fotografią. Nie jest ważne czy zdjęcia to Twoje hobby czy profesja. FotoFolder dopasuje się do Twoich potrzeb!</OpinionsText>
                <Slider {...settings} style={{maxWidth:'1200px',width:'100%'}}>
                    <Opinion>
                        <OpinionAvatar src={Avatar} />
                        <OpinionName>
                            Wioletta Wiola
                        </OpinionName>
                        <OpinionText>
                            FotoFolder jest niezwykle użyteczny i intuicyjny w użyciu. Dzięki niemu mogę łatwo katalogować i porządkować moje zdjęcia, co znacznie ułatwia zarządzanie moimi zbiorami. Funkcja udostępniania pozwala mi na dzielenie się swoimi ulubionymi zdjęciami z rodziną i przyjaciółmi, a także sprzedawanie ich innym użytkownikom. To fantastyczne narzędzie zarówno dla amatorów, jak i profesjonalistów fotografii. Polecam ją każdemu, kto chce uporządkować swoje zdjęcia i podzielić się nimi z innymi!
                        </OpinionText>
                    </Opinion>
                    <Opinion>
                        <OpinionAvatar src={Avatar} />
                        <OpinionName>
                            Wioletta Wiola
                        </OpinionName>
                        <OpinionText>
                            FotoFolder jest niezwykle użyteczny i intuicyjny w użyciu. Dzięki niemu mogę łatwo katalogować i porządkować moje zdjęcia, co znacznie ułatwia zarządzanie moimi zbiorami. Funkcja udostępniania pozwala mi na dzielenie się swoimi ulubionymi zdjęciami z rodziną i przyjaciółmi, a także sprzedawanie ich innym użytkownikom. To fantastyczne narzędzie zarówno dla amatorów, jak i profesjonalistów fotografii. Polecam ją każdemu, kto chce uporządkować swoje zdjęcia i podzielić się nimi z innymi!
                        </OpinionText>
                    </Opinion>
                    <Opinion>
                        <OpinionAvatar src={Avatar} />
                        <OpinionName>
                            Wioletta Wiola
                        </OpinionName>
                        <OpinionText>
                            FotoFolder jest niezwykle użyteczny i intuicyjny w użyciu. Dzięki niemu mogę łatwo katalogować i porządkować moje zdjęcia, co znacznie ułatwia zarządzanie moimi zbiorami. Funkcja udostępniania pozwala mi na dzielenie się swoimi ulubionymi zdjęciami z rodziną i przyjaciółmi, a także sprzedawanie ich innym użytkownikom. To fantastyczne narzędzie zarówno dla amatorów, jak i profesjonalistów fotografii. Polecam ją każdemu, kto chce uporządkować swoje zdjęcia i podzielić się nimi z innymi!
                        </OpinionText>
                    </Opinion>
                    <Opinion>
                        <OpinionAvatar src={Avatar} />
                        <OpinionName>
                            Wioletta Wiola
                        </OpinionName>
                        <OpinionText>
                            FotoFolder jest niezwykle użyteczny i intuicyjny w użyciu. Dzięki niemu mogę łatwo katalogować i porządkować moje zdjęcia, co znacznie ułatwia zarządzanie moimi zbiorami. Funkcja udostępniania pozwala mi na dzielenie się swoimi ulubionymi zdjęciami z rodziną i przyjaciółmi, a także sprzedawanie ich innym użytkownikom. To fantastyczne narzędzie zarówno dla amatorów, jak i profesjonalistów fotografii. Polecam ją każdemu, kto chce uporządkować swoje zdjęcia i podzielić się nimi z innymi!
                        </OpinionText>
                    </Opinion>
                </Slider>
            </Opinions>
        </HomeContainer>
    );
};

export default Home;
