import styled, {keyframes} from 'styled-components';
import {Link} from "react-router-dom";
export const PhotofolderContainer = styled.section`
  width:100%;
  height:100%;
  min-height:800px;
  background-color:white;
  position:relative;
 
  @media(max-width:500px){
    min-height:0px;
    height:auto;
  }
`
export const Container = styled.div`
  width:100%;
  max-width:1200px;
  margin:0 auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
`
export const CodeBar = styled.div`
  width:100%;
  background-color:var(--blue);
  padding:10px 0;
  margin-bottom:20px;
  @media(max-width:1200px){
    padding-left:15px;
  }
`
export const CodeBarContainer = styled.div`
  width:100%;
  max-width:1200px;
  margin:0 auto;
  padding:20px 0;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
`
export const YourCode = styled.span`
  font-size:2rem;
  color:var(--black);
  font-weight:700;
  margin-right:10px;
  @media(max-width:800px){
    font-size:1rem;
  }
  @media(max-width:450px){
    font-size:1rem;
  }
`
export const PhotofolderHeader = styled.h1`
  font-size:3rem;
  color:var(--black);
  font-weight:700;
  margin:0;
  font-family: var(--fontOther);
  width:100%;
  text-align:left;
  @media(max-width:800px){
    font-size:2rem;
  }
  @media(max-width:650px){
    font-size:1.8rem;
  }
`
export const PhotoFiltersContainer = styled.div`
  background-color:#EFEFEF;
  height:250px;
  width:100%;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content:center;
`
export const AddToCollection = styled.span`
  font-size:1.6rem;
  color:var(--black);
  margin:10px 10px 10px 0;
`
export const TypeOfProduct = styled(AddToCollection)`
  width:70%;
`
export const PlusButton = styled.span`
  width:280px;
  height:50px;
  color:white;
  font-size:2rem;
  border-radius:100px;
  font-family:var(--fontOther);
  font-weight:400;
  line-height:0.6;
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--green);
  span{
    font-size:3rem;
    font-weight:400;
    margin-left:15px;
  }
`
export const SizesContainer = styled.div`
  width:280px;
  height:40px;
  background-color:#fff;
  border-radius:100px;
  display:flex;
  justify-content:space-around;
  align-items:center;
  margin:20px 0 40px 0;
`
export const Size = styled.span`
  height:36px;
  width:87px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:100px;
  cursor:pointer;
  font-size:1.5rem;
  font-family:var(--fontOther);
  font-weight:400;
  ${props => props.active ? "background-color:var(--yellow)" : null};
  ${props => props.active ? "color:#000000" : "color:#A4A4A4"};
`
export const Select = styled.select`
  border:none;
  outline:none;
  border-radius:0;
  background-color:var(--lightGrey);
  height:40px;
  padding:0 10px;
  margin-right:5px;
  width:300px;
  font-size:2rem;
`
export const PhotosContainer = styled.ul`
  width:100%;
  position:relative;
  @media(max-width:1220px){
    width:95%;
  }
`
export const Overlay = styled.div`
  position:absolute;
  bottom:7px;
  left:0;
  width:100%;
  height:40px;
  z-index:50;
  background-color:rgba(255,255,255,0.6);
  justify-content:center;
  align-items:center;
  display:none;
`
export const GalleryPhotoContainer = styled.li`
  width: 19.9472%;
  height: auto;
  position: absolute;
  &:hover ${Overlay} {
    display: flex;
  }
cursor:pointer;
  @media(max-width:1200px) and (min-width:920px){
    width:24.9472%
  }
  @media(max-width:920px){
    width:33.2%;
  }
  @media(max-width:650px){
    width:49.9472%;
  }
`
export const GalleryPhoto = styled.img`
  width:100%;
  height:auto;
`
export const OverlayHolder = styled.div`
  width:100%;
  height:100%;
  position:relative;
  z-index:50;
`
export const BagText = styled.span`
  font-size:13px;
  font-family:var(--fontOther);
  font-weight:600;
  line-height:45px;
  color:#282828;
`
export const Photo = styled.div`
  width:380px;
  height:360px;
  display:flex;
  flex-direction:column;
  margin:10px;
  justify-content:flex-start;
  align-items:flex-start;
  position:relative;
`
export const ZoomPhoto = styled.div`
  display:flex;
  width:100%;
  min-width:340px;
  top:0;
  position:absolute;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  z-index:100;
  //@media(max-width:1200px){
  //  align-items:center;
  //}
  ${props => props.zoom ? "box-shadow:0px 0px 25px rgba(0, 0, 0, 0.3);" : null};
  ${props => props.zoom ? "background-color:#FFFFFF" : null};
  ${props => props.zoom ? "border-radius:10px;" : null};
`
export const CloseButtonContainer = styled.div`
  width:100%;
  display:none;
  justify-content: flex-end;
  align-items: center;
  padding:20px 30px;
  @media(max-width:1200px){
    display:flex;
  }
`
export const PhotoImg = styled.img`
  width:330px;
  height:auto;
  box-shadow:0px 0px 25px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`
export const PhotoImage = styled.div`
  width:100%;
  height:340px;
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  span{
    color:white;
    cursor: pointer;
    font-size:10rem;
  }
  //@media(max-width:1200px){
  //  width:340px;
  //}
`
export const InputNumber = styled.input`
  -moz-appearance: textfield;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  width:80px;
  outline:none;
  border:none;
  height:40px;
  padding:0;
  font-size:5rem;
  background-color:transparent;
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
`
export const AmountContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`
export const IncrementDecrementButton = styled.span`
  height:40px;
  cursor:pointer;
  background-color:var(--lightGray);
  display:flex;
  justify-content:center;
  padding:10px;
  align-items:Center;
  color:var(--black);
  font-size:2rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`
export const CloseContainer = styled.div`
  width:100%;
  height:10%;
  
  display:flex;
  justify-content:flex-end;
  align-items:Center;
`
export const FullscreenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  z-index: 10;
`;
export const OpacityBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  z-index: 15;
`;
export const AnimatedGalleryPhoto = styled.img`
  position: absolute;
  cursor: pointer;
  z-index: ${props => props.show ? 11 : 1};
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height:80%;
  width:auto;
  max-width:99%;
`;
export const PhotoInteraction = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 70px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const OpacityInteraction = styled.div`
  position:absolute;
  width:100%;
  top:0;
  left:0;
  height:70px;
  padding: 0 20px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  @media(max-width:1200px){
    display:none;
  }
`
export const BuyPhoto = styled.span`
  background-color:var(--magenta);
  padding:10px 20px;
  color:#FFF;
  font-family:var(--fontOther);
  font-weight:400;
  font-size:2rem;
  cursor:pointer;
`
export const OpacityOptions = styled.div`
  width:1200px;
  max-height:900px;
  height:90%;
  display:flex;
  justify-content:center;
  align-items:center;
  @media(max-width:1200px){
    width:100%;
    height:100%;
  }
  @media(max-width:990px){
    flex-direction:column;
  }
`
export const MobileCloseContainer = styled.div`
  width:100%;
  height:5%;
  background-color:#fff;
  padding:10px 20px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  @media(min-width:990px){
    display:none;
  }
`
export const PhotoThumbnail = styled.div`
  width:35%;
  height:100%;
  background-image:${props => props.image ? `url(${props.image})` : null};
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center;
  @media(max-width:1100px){
    width:30%;
  }
  @media(max-width:990px){
    width:100%;
    height:35%;
  }
`
export const Blur = styled.div`
  width:100%;
  height:100%;
  backdrop-filter: blur(20px);
  display:flex;
  justify-content:center;
  align-items:center;
`
export const Thumbnail = styled.img`
  width:80%;
  height:auto;
  @media(max-width:990px){
    width:auto;
    height:95%;
  }
`
export const PhotoOptions = styled.div`
  width:65%;
  background-color:#fff;
  height:100%;
  display:flex;
  flex-direction:column;
  @media(max-width:1100px){
    width:70%;
  }
  @media(max-width:990px){
    width:100%;
    height:60%;
  }
`
export const Options = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-start;
  padding:20px 40px;
  @media(max-width:690px){
    padding:20px 10px;
  }
`
export const Option = styled.span`
  font-size:15px;
  font-weight:${props => props.chosen ? "700" : "500"};
  font-family:var(--fontOther);
  color:#282828;
  padding:10px 0;
  margin:0 10px;
  cursor:pointer;
  border-bottom:${props => props.chosen ? "3px solid var(--blue);" : "none"};
`
export const Variants = styled.div`
  width:100%;
  flex-grow:1;
  display:flex;
  padding:0 40px;
  flex-direction:column;
  @media(max-width:690px){
    padding:0 10px;
  }
`
export const Variant = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items: center;
  padding:20px 0;
  border-bottom:${props => !props.last ? "1px solid #E9E9E9" : "none"};
  @media(max-width:650px){
  flex-direction:column;
}
`
export const VariantsTitle = styled.span`
  font-size:14px;
  font-weight:400;
  color:#282828;
  line-height:30px;
  font-family:var(--fontOther);
  width:100%;
  text-align:left;
`
export const VariantSize = styled.span`
  font-size:14px;
  font-weight:600;
  font-family:var(--fontOther);
  color:#282828;
`
export const Quantity = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:30px;
`
export const Minus = styled.div`
  width:40px;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  border: 1px solid #848484;
  border-radius: 100px 0 0 100px;
  font-family:var(--fontOther);
  font-size:22px;
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const Plus = styled.div`
  width:40px;
  height:100%;
  display:flex;
  font-size:22px;
  justify-content:center;
  align-items:center;
  border-radius: 0 100px 100px 0;
  border: 1px solid #848484;
  font-family:var(--fontOther);
  cursor:pointer;
  background: rgba(168, 218, 220, 0.20);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const QuantityText = styled.span`
  display:flex;
  width:40px;
  height:100%;
  justify-content:center;
  align-items:center;
  font-size:14px;
  border-top: 1px solid #848484;
  font-family:var(--fontOther);
  border-bottom: 1px solid #848484;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const Sum = styled.span`
  font-size:14px;
  font-family:var(--fontOther);
  font-weight:400;
  width:95px;
  color:#282828;
`
export const SumBold = styled(Sum)`
  font-weight:600;
`
export const AddToCart = styled.button`
  font-size:14px;
  font-family:var(--fontOther);
  font-weight:400;
  color:#fff;
  width:170px;
  height:35px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--magenta);
  border-radius:100px;
  border:none;
  outline:none;
`
export const ViewCart = styled(Link)`
  font-size:14px;
  font-family:var(--fontOther);
  font-weight:400;
  color:#fff;
  width:170px;
  height:35px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--magenta);
  border-radius:100px;
  border:none;
  outline:none;
`
export const CartContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  padding:20px;
  border-top:1px solid #E9E9E9;
`
export const FreeShipping = styled.span`
  font-size:14px;
  font-family:var(--fontOther);
  font-weight:600;
  color:#282828;
  margin-right:20px;
  @media(max-width:460px){
    font-size:10px;
    margin-right:0;
  }
`
export const FirstContainer = styled.div`
  display:flex;
  align-items: center;
  justify-content:space-between;
  width:60%;
  padding-right:30px;
  @media(max-width:650px){
    width:100%;
    justify-content:space-between;
  }
`
export const SecondContainer = styled.div`
  display:flex;
  align-items: center;
  width:40%;
  @media(max-width:650px){
    width:100%;
    justify-content:space-between ;
    margin-top:20px;
  }
`
