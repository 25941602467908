import React, { useState } from 'react';
import {
    ColumnHeader,
    ColumnList,
    ColumnListElement,
    ColumnText,
    Container,
    FooterColumn,
    FooterColumns, FooterLine, FooterLogo, FooterLogoText, FooterMobile,
    FooterNewsletter, FooterSocials, FooterSocialsIcons,
    FooterStyled, FooterWeb, Logo, NavLogo,
    NewsletterContainer,
    NewsletterHeader,
    NewsletterSignup, NewsletterSignupButton,
    NewsletterSignupContainer, NewsletterTerms, SocialsLogo
} from './Footer.styles';
import {ReactComponent as LightLogo} from "../../assets/logofotofolderlight.svg"
import {ReactComponent as LinkedIn} from "../../assets/linkedin.svg"
import {ReactComponent as Facebook} from "../../assets/facebook.svg"

const Footer = () => {
    return (
        <FooterStyled>
            <Container>
                <FooterMobile>
                    <NavLogo to={`/`}>
                        <LightLogo style={{marginBottom:'20px'}}/>
                    </NavLogo>
                    <ColumnList>
                        <ColumnListElement><a href="#">Jak zamawiać</a></ColumnListElement>
                        <ColumnListElement><a href="">Dla Fotografów</a></ColumnListElement>
                        <ColumnListElement><a href="">O nas</a></ColumnListElement>
                        <ColumnListElement><a href="">Regulamin</a></ColumnListElement>
                        <ColumnListElement><a href="">Polityka prywatności</a></ColumnListElement>
                        <ColumnListElement><a href="">Kontakt</a></ColumnListElement>
                    </ColumnList>
                    <FooterSocials>
                        <a href="#">
                            <LinkedIn style={{width:'40px', height:'40px'}}/>
                        </a>
                        <a href="#">
                            <Facebook style={{width:'40px', height:'40px'}}/>
                        </a>
                    </FooterSocials>
                </FooterMobile>
                <FooterWeb>
                    <FooterColumn bigger>
                        <NavLogo to={`/`}>
                            <LightLogo style={{marginBottom:'20px'}}/>
                        </NavLogo>
                        <ColumnHeader>Najlepszy sposób na udostępnianie zdjęć z sesji fotograficznych</ColumnHeader>
                        <ColumnText>Wykorzystaj moc sztucznej inteligencji, która sortuje zdjęcia i taguje je biorąc pod uwagę zaawansowany algorytm rozpoznawania twarzy. </ColumnText>
                    </FooterColumn>
                    <FooterColumn>
                        <ColumnList>
                            <ColumnListElement><a href="#">Jak zamawiać</a></ColumnListElement>
                            <ColumnListElement><a href="">Dla Fotografów</a></ColumnListElement>
                            <ColumnListElement><a href="">O nas</a></ColumnListElement>
                        </ColumnList>
                    </FooterColumn>
                    <FooterColumn>
                        <ColumnList>
                            <ColumnListElement><a href="">Regulamin</a></ColumnListElement>
                            <ColumnListElement><a href="">Polityka Prywatności</a></ColumnListElement>
                            <ColumnListElement><a href="">Kontakt</a></ColumnListElement>
                        </ColumnList>
                    </FooterColumn>
                </FooterWeb>
            </Container>
        </FooterStyled>
    );
};

export default Footer;
