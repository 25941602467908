import React, {useContext, useState} from 'react';
import {
    CheckoutBag, CheckoutBagContainer,
    Hamburger, HamburgerPart, HamburgerPartLower, HamburgerPartUpper,
    LogOut, MenuButtonContainer, MenuButtonFirst, MenuButtonSecond, MenuItem,
    Navbar,
    NavContainer, NavInteract,
    NavLogin,
    NavLogo,
    NavOption,
    NavOptions, NavRegister, Sidebar, User
} from './Header.styles';
import {ReactComponent as Bag} from '../../assets/Bag.svg'
import {ReactComponent as Lock} from '../../assets/lock.svg'
import {useLocation, useNavigate} from 'react-router-dom';
import {UserContext} from "../../utils/UserContext";
import {ReactComponent as LogoText} from "../../assets/logofotofolder2.svg"
import {
    Button,
    CartItems,
    CartTitle,
    HoverCart,
    HoverCartItem,
    HoverItemDetails,
    HoverItemImage, HoverItemTitle, HoverText, HoverTextContainer, Invisible, QuantityContainer, SumContainer, SumText
} from "../Checkout/Cart.styles";

const SubCart = ({openCart, cart}) => {
    const navigate = useNavigate()
    return (
        <HoverCart show={openCart}>
            <CartTitle>Koszyk ({cart.length})</CartTitle>
            <CartItems scroll={cart.length > 3}>
                {cart.length > 0 ? cart.map((item, index) => {
                    return (
                        <HoverCartItem key={index}>
                            <HoverItemImage image={item.image}/>
                            <HoverItemDetails>
                                <HoverItemTitle>{item.type} {item.size}</HoverItemTitle>
                                <QuantityContainer>
                                    <HoverText>
                                        {item.quantity} szt.
                                    </HoverText>
                                    <HoverText>
                                        {item.cost} zł
                                    </HoverText>
                                </QuantityContainer>
                            </HoverItemDetails>
                        </HoverCartItem>
                    )
                }): <p>Brak produktów w koszyku</p>}
            </CartItems>
            {cart.length > 0 ?
                <SumContainer>
                    <HoverTextContainer>
                        <HoverText>Dostawa:</HoverText>
                        <HoverText>15zł (Kurier)</HoverText>
                    </HoverTextContainer>
                    <HoverTextContainer>
                        <SumText>Do zapłaty:</SumText>
                        <SumText>{cart.reduce((total, photo) => {
                            return total + photo.cost;
                        }, 0) + 15}zł</SumText>
                    </HoverTextContainer>
                    <Button onClick={() => navigate("/cart")}>
                        <Lock />
                        Przejdź do koszyka
                        <Invisible />
                    </Button>
                </SumContainer>
            : null}
        </HoverCart>
    )
}
const Header = ({sticky, user}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openHamburger, setOpenHamburger] = useState(false)
    const [openCart, setOpenCart] = useState(false);
    const {cart,setUser} = useContext(UserContext);
    const handleLogOut = () => {
        setUser(null);
        sessionStorage.setItem("refresh", JSON.stringify(undefined))
        sessionStorage.setItem("access", JSON.stringify(undefined))
        sessionStorage.setItem("user", JSON.stringify(undefined))
        navigate("/");
    }
    const handleShowCart = (show) => {
        setOpenCart(show);
    }
    return (
        <Navbar home={location.pathname === "/"} id="navbar" makeSticky={sticky}>
            <NavContainer>
                <NavLogo to={`/`}>
                    <LogoText/>
                </NavLogo>
                <NavOptions>
                    <NavOption>O nas</NavOption>
                    <NavOption>Cennik</NavOption>
                    <NavOption>Regulamin</NavOption>
                    <NavOption>Wsparcie</NavOption>
                </NavOptions>
                <NavInteract>
                    {user !== null
                        ?
                        <>
                            <CheckoutBagContainer to={"/cart"} onMouseEnter={() => handleShowCart(true)} onMouseLeave={() => handleShowCart(false)}>
                                <CheckoutBag quantity={"0"}  number={cart.length} >
                                    <Bag/>
                                    <SubCart cart={cart} openCart={openCart}/>
                                </CheckoutBag>
                            </CheckoutBagContainer>
                            <User to={user.user_profile_id !== null ? `/user/my-account` : `/photographer/my-panel`}>
                                      <span className="material-symbols-outlined">
                                    account_circle
                                    </span>
                            </User>
                            <LogOut onClick={handleLogOut}>
                                wyloguj
                                <span className="material-symbols-outlined">logout</span>
                            </LogOut>
                        </>
                        :
                        <>
                            <CheckoutBagContainer to={"/cart"} onMouseEnter={() => handleShowCart(true)} onMouseLeave={() => handleShowCart(false)}>
                                <CheckoutBag quantity={"0"}  number={cart.length} >
                                    <Bag/>
                                    <SubCart cart={cart} openCart={openCart}/>
                                </CheckoutBag>
                            </CheckoutBagContainer>
                            <NavLogin to={`/login`}>
                                Zaloguj się
                            </NavLogin>
                            <NavRegister to={`/register`}>
                                Nowe konto
                            </NavRegister>
                        </>
                    }
                    <Hamburger onClick={() => setOpenHamburger(!openHamburger)}>
                        <HamburgerPartUpper animate={openHamburger}/>
                        <HamburgerPart animate={openHamburger}/>
                        <HamburgerPartLower animate={openHamburger}/>
                    </Hamburger>
                </NavInteract>
                <Sidebar className={openHamburger ? 'open' : ''}>
                    <Hamburger onClick={() => setOpenHamburger(!openHamburger)}>
                        <HamburgerPartUpper animate={openHamburger}/>
                        <HamburgerPart animate={openHamburger}/>
                        <HamburgerPartLower animate={openHamburger}/>
                    </Hamburger>
                    <MenuItem>Moje konto</MenuItem>
                    <MenuItem>Cennik</MenuItem>
                    <MenuItem>O nas</MenuItem>
                    <MenuItem>Regulamin</MenuItem>
                    <MenuItem>Wsparcie</MenuItem>
                    <MenuButtonContainer>
                        <MenuButtonFirst>
                            Zaloguj się
                        </MenuButtonFirst>
                    </MenuButtonContainer>
                    <MenuButtonContainer>
                        <MenuButtonSecond>
                            Nowe konto
                        </MenuButtonSecond>
                    </MenuButtonContainer>
                </Sidebar>
            </NavContainer>
        </Navbar>
    );
};

export default Header;
