import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  top:-130px;
  left:0;
  z-index:4;
  width:100vw;
  height:calc(100% + 130px);
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:rgba(0,0,0,0.5);
`
export const Modal = styled.div`
  width:30%;
  min-width:450px;
  max-width:600px;
  padding:50px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  background-color:white;
`
export const MyEditHeader = styled.h1`
  font-size:3rem;
  font-weight:700;
  width:100%;
  text-align:left;
  display:flex;
  align-items:center;
  font-family:var(--fontOther);
  color:var(--black);
  span{
    margin-left:5px;
    font-size:3rem;
    font-weight:700;
  }
`
export const Label = styled.label`
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
  margin-top:15px;
`
export const Input = styled.input`
  width:400px;
  border:none;
  outline:none;
  font-size:3rem;
  font-family:var(--fontOther);
  font-weight:700;
  background-color:var(--darkGray);
  height:50px;
`
export const InputsContainer = styled.form`
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
`
export const ButtonsContainer = styled.div`
  display:flex;
  justify-content:flex-end;
  align-items:center;
  width:100%;
  margin-top:25px;
`
export const Button = styled.button`
  background-color:var(--black);
  outline:none;
  border:none;
  display:flex;
  align-items:center;
  color:white;
  font-weight:700;
  font-size:1.6rem;
  margin-right:15px;
  span{
    margin-left:10px;
  }
  padding:10px 25px;
`