import React, { useContext, useState } from 'react';
import arrow from '../../assets/arrow_outward.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../utils/UserContext';
import { useNavigate } from 'react-router-dom';
import {
  BackToLogin,
  Container, Forgotten, Highlight, Input,
  InputLabel,
  InputsContainer, ResetButton,
  ResetContainer,
  ResetHeader,
  ResetPage,
  ResetSubHeader,
  Text
} from "./Reset.styles";
import {ErrorText} from "../Login/Login.styles";
const Reset = () => {
  const [email,setEmail] = useState("");
  const [error,setError] = useState("");
  const [success, setSuccess] = useState(false);
  const {setUser} = useContext(UserContext)
  const navigate = useNavigate()
  const handleChangeLogin = (e) =>{
    setEmail(e.target.value);
  }
  const handleResetPassword = async (e) =>{
    e.preventDefault();
    const requestOptions = {
      method:"POST",
      headers: {'Content-Type': 'application/json'},
      body:JSON.stringify({email})
    }
    await fetch(`${process.env.REACT_APP_API_URL}/api/users/auth/send-password-reset/`,requestOptions)
      .then(r =>{
        if(r.ok){
          setSuccess(true);
        }else{
          setError("Coś poszło nietak");
        }
      })
      .catch(err=>console.log(err))
  }
  return (
    <ResetPage>
      <Container>
        <ResetContainer onSubmit={handleResetPassword}>
          <ResetHeader>{success ? "Gotowe" : "Reset hasła"}</ResetHeader>
          <ResetSubHeader>{success ? "Sprawdz skrzynkę mailową. Jeżeli nie otrzymałeś wiadomości skontaktuj się z działem wsparcia." :
          "Wpisz poniżej adres email połączony z Twoim kontem, a następnie postępuj i instrukcjami zawarymi w mailu."}</ResetSubHeader>
          <InputsContainer>
            {!success ? <Input type="text" value={email} onChange={handleChangeLogin} required label="Wpisz adres email"/> : null }
            <ErrorText>{error}</ErrorText>
            {success ? <BackToLogin to={"/login"}>
              Powrót do logowania
            </BackToLogin> :<ResetButton type="submit">
              Resetuj hasło
            </ResetButton>}
          </InputsContainer>
        </ResetContainer>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Container>
    </ResetPage>
  );
};

export default Reset;
