import styled from 'styled-components';

export const AddFolderContainer = styled.main`
  width:100%;
  max-width:var(--maxWidth);
  margin:0 auto;
  display:flex;
  position:relative;
  flex-direction:column;
  padding:40px 0;
  justify-content: flex-start;
  align-items:flex-start;
`
export const AddFolderHeader = styled.h1`
  margin:0;
  font-family:var(--fontOther);
  font-weight:700;
  color:var(--black);
  font-size:4rem;
`
export const Container = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
`
export const LeftSide = styled.div`
  width:30%;
  min-width:280px;
  height:auto;
  background-color:var(--lightGray);
  border-top:3px solid var(--yellow);
  padding:25px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
`
export const StructureHeader = styled.h3`
  margin:0;
  font-family:var(--fontOther);
  font-size:2rem;
  color:var(--black);
  font-weight:700;
`
export const RightSide = styled.div`
  width:70%;
  padding-left:50px;
  height:auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items: flex-start;
`
export const ProjectNameContainer = styled.div`
  display:flex;
  align-items:center;
  cursor:pointer;
  width:100%;
`
export const FolderNameText = styled.span`
  padding: 0 10px;
  width:100%;
`
export const FirstVerticalDots = styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  align-items:flex-start;
  border-left:1px dotted black;
  margin-left:10px;
`
export const HorizontalDots = styled.div`
  width:15px;
  height:1px;
  border-bottom:1px dotted black;
`
export const SecondVerticalDots = styled.div`
  min-height: 10px;
  margin-left: 27px;
  border-left: 1px dotted black;
  display: flex;
  width:90%;
  flex-direction: column;
  align-items: flex-start;
`
export const ChooseProjectContainer = styled.div`
  width:70%;
  margin:40px 0;
  display:flex;
  justify-content:space-between;
  align-items:center;
`
export const ChooseProjectHeader = styled.h3`
  margin:0;
  font-weight:700;
  font-size:2rem;
`
export const ChooseProjectSelect = styled.select`
  width:320px;
  height:30px;
  border:0;
  outline:none;
  border-radius:0;
  background-color:var(--lightGray);
  option{
    font-size:1.6rem;
    font-weight:400;
  }
`
export const ChooseProjectButton = styled.button`
  color:white;
  font-size:1.6rem;
  font-weight:700;
  display:flex;
  border:none;
  outline:none;
  justify-content:center;
  align-items:center;
  background-color:var(--black);
  padding:13px;
  span{
    margin-left:8px;
    font-weight:700
  }
`
export const PlaceholderContainer = styled.div`
  width:100%;
  height:200px;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const ChooseProjectPlaceholder = styled.h3`
  margin:0;
  font-size:2rem;
  font-weight:700;
  font-family:var(--fontOther);
  color:var(--grey);
`
export const AddOverridingFolderContainer= styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  margin-top:20px;
`
export const AddOverridingHeader = styled.h3`
  font-weight:400;
  font-size:1.6rem;
  color:var(--black);
  margin:5px 0;
`
export const OverridingFolderInput = styled.input`
  width:100%;
  outline:none;
  padding:10px;
  margin:5px 0;
  &:focus{
    outline: none;
  }
  &::placeholder{
    color:var(--grey);
  }
  border:none;
  font-size:1.6rem;
  
`
export const OverridingFolderAdd = styled.button`
  background-color:var(--black);
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:1.6rem;
  font-weight:400;
  color:white;
  padding:10px 15px;
  outline:none;
  align-self:flex-end;
  border:none;
  margin:10px 0;
  span{
    margin-left:10px;
  }
`
export const FolderPath = styled.h3`
  font-family:var(--fontOther);
  font-size:2rem;
  font-weight:400;
  color:var(--black);
`
export const InactiveDropzone = styled.div`
  width:100%;
  height:500px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`
export const Dropzone = styled.form`
  width:100%;
  position:relative;
  height:500px;
  background-color:var(--lightGrey);
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`
export const PopulatedDropzone = styled.form`
  width:100%;
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`
export const DropzoneImage = styled.img`
  width:100px;
  height:auto;
`
export const DropzoneText  = styled.p`
  margin:5px 0;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const InputField = styled.input`
  display:none;
`
export const DropzoneInvisible = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const DropzoneArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  border: 2px dashed var(--black);
  background-color: var(--lightGray);
  opacity:0.9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const InputsLabel = styled.label`
  height: 100%;
  display: flex;
  cursor:pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
`
export const PopulatedInputsLabel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
`
export const LabelsContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const PhotosLabel = styled.span`
    font-size:1.6rem;
  text-align:center;
    color:var(--black);
  font-weight:700;
`
export const PhotosListed = styled.div`
  width:100%;
  display:flex;
  margin:5px 0;
  justify-content:center;
  align-items:center;
`
export const ListedPhotoImage = styled.img`
  width:15%;
  //height:auto;
`
export const ListedPhotoTest =styled.div`
    background-size:contain;
    background-repeat:no-repeat;
  background-position:center;
  width:15%;
  height:100%;
  min-height:130px;
  background-color:var(--lightGrey);
`
export const ListedPhotoName = styled.span`
  width:20%;
  text-align:center;
  text-overflow:ellipsis;
  overflow:hidden;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const ListedPhotoFlag = styled.span`
  width:10%;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const ListedPhotoDate = styled.span`
  width:15%;
  text-align:center;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const ListedPhotoNumber = styled.span`
  width:10%;
  text-align:center;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const ListedPhotoSize = styled.span`
  width:15%;
  text-align:center;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const ListedPhotoDelete = styled.span`
  width:5%;
  text-align:center;
  font-size:1.6rem;
  font-weight:400;
  cursor:pointer;
  color:var(--black);
`
export const ListedPhotoInfo = styled.span`
  width:5%;
  text-align:center;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const Flag = styled.img`
  width:20px;
  height:20px;
`
export const FilesActions = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  margin-top:50px;
`
export const FilesActionsHeader = styled.h3`
  margin:10px 0;
  font-size:2rem;
  font-weight:700;
  font-family:var(--fontOther);
  color:var(--black);
`
export const FilesAction = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    margin:10px 0;
`
export const FileActionHeader = styled.h5`
  margin:0;
  font-size:1.6rem;
  width:25%;
  font-weight:400;
  color:var(--black);
`
export const FileActionButton = styled.button`
  border:none;
  outline:none;
  margin:0 20px;
  background-color:var(--black);
  padding:10px 20px;
  font-size:1.6rem;
  font-weight:400;
  color:white;
  display:flex;
  justify-content:center;
  align-items:center;
  span{
    font-size:2.5rem;
    margin-left:10px;
  }
`
export const UploadButton = styled.label`
  margin:0 5px;
  background-color:var(--black);
  color:white;
  font-weight:700;
  font-size:1.6rem;
  padding:10px 15px;
  display:flex;
  justify-content:center;
  align-items:center;
  span{
    font-size:3rem;
    margin-left:5px;
  }
`