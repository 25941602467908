import styled from 'styled-components'
import {Link} from "react-router-dom";

export const FooterStyled = styled.footer`
  background-color:var(--black);
  width:100%;
  padding:12px;
  height:auto;
  z-index:100;
`
export const Container = styled.div`
  max-width:var(--maxWidth);
  margin:0 auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`
export const FooterWeb = styled.div`
  display:none;
  @media(min-width:965px){
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    height:100%;
    margin:42px 0;
    width:100%;
  }
`
export const FooterMobile = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  padding:40px 10px;
  @media(min-width:965px){
    display:none;
  }
`
export const FooterColumn = styled.div`
  display:flex;
  flex-direction:column;
  width:${props => props.bigger ? "33%" : "25%"};
  height:100%;
  justify-content:${props => props.bigger ? "flex-start" : "center"};
  align-items:flex-start;
`
export const ColumnHeader = styled.h1`
  font-size:2rem;
  font-weight:600;
  color:white;
  font-family:var(--fontOther);
`
export const ColumnText = styled.p`
  font-size:1.4rem;
  font-weight:700;
  font-family:var(--subFont);
  color:white;
`
export const NavLogo = styled(Link)`
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
export const ColumnList = styled.ul`
  list-style:none;
  display:flex;
  flex-direction:column;
  padding:0;
  align-items:flex-start;
  justify-content:center;
`
export const ColumnListElement = styled.li`
  font-size:1.6rem;
  margin:10px 0;
  font-family:var(--fontOther);
  font-weight:600;
  color:white;
  a{
    color:White;
  }
`
export const FooterNewsletter = styled.div`
  display:flex;
  align-self:flex-start;
  justify-content:flex-start;
  align-items:flex-start;
`
export const NewsletterContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-end;
`
export const NewsletterHeader = styled.h1`
  font-size:2rem;
  margin-right:25px;
  font-weight:700;
  color:white;
  font-family:var(--fontOther);
  @media(max-width:450px){
    font-size:1.2rem;
  }
`
export const NewsletterSignup = styled.input`
  width:300px;
  font-family:var(--fontOther);
  font-weight:700;
  outline:none;
  height:100%;
  font-size:3.3rem;
  border:none;
  padding-left:5px;
  @media(max-width:700px){
    width:200px;
    height:50px;
    font-size:2rem;
  }
  @media(max-width:450px){
    width:175px;
    height:35px;
    font-size:1.2rem;
  }
`
export const NewsletterSignupButton = styled.button`
  border:none;
  background-color:var(--yellow);
  color:var(--black);
  display:flex;
  font-weight:700;
  font-size:1.6rem;
  align-items:center;
  cursor:pointer;
  padding:15px 35px 15px 15px;
  height:100%;
  outline:none;
  @media(max-width:500px){
    height:50px;
    width:200px;
    justify-content:center;
  }
  @media(max-width:450px){
    height:35px;
    width:175px;
    font-size:1.2rem;
  }
`
export const NewsletterSignupContainer = styled.form`
  display:flex;
  align-items:flex-start;
  margin-bottom:10px;
  height:55px;
  @media(max-width:500px){
    flex-direction:column;
    margin-bottom:20px;
    height:auto;
    width:100%;
  }
`
export const NewsletterTerms = styled.span`
  font-size:1.6rem;
  color:white;
  margin-left:5px;
  font-weight:400;
`
export const FooterLine = styled.span`
  width:100%;
  display:block;
  margin:20px 0;
  height:1px;
  background-color:white;
`
export const FooterLogo = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-right:50px;
`
export const FooterLogoText = styled.span`
  font-size:2.4rem;
  text-transform:uppercase;
  font-family:var(--fontOther);
  font-weight:700;
  margin:0 15px;
  color:white;
  @media(max-width:500px){
    display:none;
  }
`
export const Logo = styled.img`
  width:30px;
  height:auto;
`
export const SocialsLogo = styled.img`
  width:40px;
  height:auto;
`
export const FooterSocials = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const FooterSocialsIcons = styled.div`
  width:100%;
  margin-right:220px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
`
