import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import {createGlobalStyle} from 'styled-components';
import {useEffect, useMemo, useState} from 'react';
import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import MyAccount from './components/User/MyAccount/MyAccount';
import {UserContext, FolderContext} from './utils/UserContext';
import {logOut} from './utils/authentication.service';
import Photofolder from "./components/Photofolder/Photofolder";
import {QueryClientProvider, QueryClient} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import PhotographerPanel from "./components/Admin/PhotographerPanel/PhotographerPanel";
import PreparePhotofolder from "./components/Admin/PreparePhotofolder/PreparePhotofolder";
import Reset from "./components/Reset/Reset";
import {GlobalStyles} from "./components/GlobalStyles";
import Cart from "./components/Checkout/Cart";


const queryClient = new QueryClient();

function App() {
    const [sticky, setSticky] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [user, setUser] = useState(null);
    const [folder, setFolder] = useState(null);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('photoCart')) ? JSON.parse(localStorage.getItem('photoCart')) : [])
    const providerValue = useMemo(() => ({user, setUser,folder,setFolder, fullscreen, setFullscreen, cart, setCart}), [user, setUser,folder,setFolder, fullscreen, setFullscreen, cart, setCart]);
    const folderValue = {
        folder:folder,setFolder:setFolder
    }

    function myFunction() {
        if (window.pageYOffset > document.getElementById("navbar").offsetTop) {
            setSticky(true)
        } else {
            setSticky(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', myFunction)
        if (sessionStorage.getItem("user") !== null && sessionStorage.getItem("user") !== "undefined") {
            setUser({
                access: JSON.parse(sessionStorage.getItem("access")),
                refresh: JSON.parse(sessionStorage.getItem("refresh")),
                user_id: JSON.parse(sessionStorage.getItem("user")).user_id,
                user_profile_id: JSON.parse(sessionStorage.getItem("user")).user_profile_id,
                photographer_profile_id: JSON.parse(sessionStorage.getItem("user")).photographer_profile_id
            })
        } else if (sessionStorage.getItem("refresh") !== null && sessionStorage.getItem("refresh") !== "undefined") {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({refresh: JSON.parse(window.sessionStorage.getItem('refresh'))})
            };
            return fetch(`${process.env.REACT_APP_API_URL}/api/users/auth/token/refresh/`, requestOptions)
                .then(r => r.json())
                .then(data => {
                    if (data.access) {
                        sessionStorage.setItem('access', JSON.stringify(data.access));
                        sessionStorage.setItem("user", JSON.stringify({
                            user_id: data.user_id,
                            user_profile_id: data.user_profile_id,
                            photographer_profile_id: data.photographer_profile_id
                        }))
                        return null;
                    } else if (data.detail === 'Token is invalid or expired') {
                        return logOut(setUser);
                    }
                });
        }
    }, [])
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <GlobalStyles show={fullscreen}/>
                <UserContext.Provider value={providerValue}>
                <FolderContext.Provider value={folderValue}>
                    <Header sticky={sticky} user={user}/>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/user/my-account" element={<MyAccount/>}/>
                        <Route path="/photofolder" element={<Photofolder/>}/>
                        <Route path="/reset-password" element={<Reset />}/>
                        <Route path="/cart" element={<Cart />}/>
                        {user && user.photographer_profile_id !== null ?
                            <Route path="/photographer/my-panel" element={<PhotographerPanel />} />
                            : null
                        }
                        {user && user.photographer_profile_id !== null ?
                            <Route path="/photographer/prepare-photofolder" element={<PreparePhotofolder />} />
                            : null
                        }

                    </Routes>
                    <Footer/>
                </FolderContext.Provider>
                </UserContext.Provider>
            </Router>
            <ReactQueryDevtools />
        </QueryClientProvider>
)
    ;
}

export default App;
