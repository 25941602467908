import React, {useContext, useEffect, useState} from 'react';
import {
    BarContainer,
    Container, FinalBar, FolderCode, FolderName, Highlight,
    Panel,
    PanelNavigation,
    PanelNavigationContainer,
    PanelNavigationElement, PhotosQuantity, Prepare
} from "./PhotographerPanel.styles";
import Console from "./Console/Console";
import Clients from "./Clients/Clients";
import Sales from "./Sales/Sales";
import MyInfo from "./MyInfo/MyInfo";
import AddFolder from "./AddFolder/AddFolder";
import {useQuery} from "react-query";
import {authHandler} from "../../../utils/authHandler";
import {FolderContext, UserContext} from "../../../utils/UserContext";
import {handleResponse} from "../../../utils/handleResponse";
import {useNavigate} from "react-router-dom";

const PhotographerPanel = () => {
    const [chosenPanel,setChosenPanel] = useState("console")
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const { folder, setFolder } = useContext(FolderContext);
    const fetchPhotographerInfo = async () => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method:"GET",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/photographer-profile/${user.photographer_profile_id}/`, requestOptions)
            .then(r => {
                return handleResponse(r,requestOptions,setUser,navigate)
            })
            .then(r=>r.json())
    }

    const { data, status } = useQuery("photographer-info", fetchPhotographerInfo)
    const handleChangePanel = (panel) =>{
        setChosenPanel(panel)
        setFolder(null)
    }
    return (
        <Panel>
            {folder && folder.folder_photos.length > 0
                ?
                <FinalBar>
                    <BarContainer>
                        <FolderName>
                            Folder: <Highlight>{folder.name}</Highlight>
                        </FolderName>
                        <FolderCode>
                            Kod folderu: <Highlight>{folder.code}</Highlight>
                        </FolderCode>
                        <PhotosQuantity>
                            Ilość zdjęć: <Highlight>{folder.folder_photos.length}</Highlight>
                        </PhotosQuantity>
                        <Prepare onClick={()=>navigate(`/photographer/prepare-photofolder?id=${folder.id}`,{state:folder.id,replace:true})}>
                            Przygotuj Fotofolder
                            <span className="material-symbols-outlined">forward</span>
                        </Prepare>
                    </BarContainer>
                </FinalBar>
            : null}
            <PanelNavigation>
                <PanelNavigationContainer>
                    <PanelNavigationElement style={chosenPanel === "console" ? {backgroundColor:'white'} : {backgroundColor:'transparent'}} onClick={()=>handleChangePanel("console")}>Pulpit</PanelNavigationElement>
                    <PanelNavigationElement style={chosenPanel === "clients" ? {backgroundColor:'white'} : {backgroundColor:'transparent'}} onClick={()=>handleChangePanel("clients")}>Klienci</PanelNavigationElement>
                    <PanelNavigationElement style={chosenPanel === "sales" ? {backgroundColor:'white'} : {backgroundColor:'transparent'}} onClick={()=>handleChangePanel("sales")}>Sprzedaż</PanelNavigationElement>
                    <PanelNavigationElement style={chosenPanel === "my-info" ? {backgroundColor:'white'} : {backgroundColor:'transparent'}} onClick={()=>handleChangePanel("my-info")}>Moje dane</PanelNavigationElement>
                    <PanelNavigationElement style={chosenPanel === "add-folder" ? {backgroundColor:'white'} : {backgroundColor:'transparent'}} onClick={()=>handleChangePanel("add-folder")}>Dodaj <span className="material-symbols-outlined">add</span></PanelNavigationElement>
                </PanelNavigationContainer>
            </PanelNavigation>
            <Container>
                {
                    chosenPanel === "console" ?
                        <Console />
                        :
                    chosenPanel === "clients" ?
                        <Clients />
                        :
                    chosenPanel === "sales" ?
                        <Sales />
                        :
                    chosenPanel === "my-info" ?
                        <MyInfo />
                        :
                    chosenPanel === "add-folder" ?
                        <AddFolder />
                        : null
                }
            </Container>
        </Panel>
    );
};

export default PhotographerPanel;