import styled from 'styled-components';
import background3 from '../../assets/background4.png'
import background1 from '../../assets/background1.png';
import background2 from "../../assets/background54.png"

export const HomeContainer = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
export const HomeHero = styled.section`
  width: 100%;
  height: auto;
  display: flex;
    @media(max-width:800px){
        flex-direction: column-reverse;
    }
`
export const HeroLeft = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media (min-width: 800px) {
    width: 50%;
    height: 500px;
    justify-content: center;
    align-items: flex-end;
  }
`
export const HeroContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 50px 25px;
    @media(max-width:600px){
        padding:15px 25px;
    }
  @media (min-width: 800px) {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;
  }
  @media (min-width: 1250px) {
    width: 600px;
    padding-left: 0;
    padding-bottom: 0;
    justify-content: center;
  }
`
export const HeroRight = styled.div`
    width: 100%;
    height: 60vw;
    display: block;
    background-image: url(${background3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  @media (min-width: 800px) {
    width: 50%;
    height: 500px;
    display: block;
    background-image: url(${background3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`

export const Header = styled.h1`
  color: var(--black);
  font-size: 3rem;
  font-weight: 700;
  font-family: var(--fontOther);
  text-align: left;
  margin: 0;
  width: 100%;
  line-height: 56px;
    @media (max-width: 799px) {
        line-height: 45px;
    }
    @media(min-width:700px) and (max-width:800px){
        font-size:2.5rem;
    }
  @media (min-width: 800px) {
    ${props => props.center ? "text-align:center" : "text-align:left"};
    max-width: 600px;
    font-size: 5.5rem;
  }
    @media (min-width: 1200px) {
        font-size: 50px;
    }
`
export const OpinionHeader = styled(Header)`
  @media(min-width:1000px){
      max-width:100%;
  }
`
export const IdealMatchHeader = styled(Header)`
  line-height:47px;
`
export const Text = styled.span`
  color: var(--black);
  max-width: 1200px;
  font-weight: 400;
  text-align: left;
  margin: 15px 0;
  width: 100%;
  font-size: 1.5rem;
  font-family: var(--subFont);
    @media (max-width: 799px) {
        line-height: 30px;
        font-weight:700;
    }
    @media(min-width:700px) and (max-width:800px){
        font-size:1.2rem;
    }
    @media(min-width:1200px){
        font-size:18px;
    }
  @media (min-width: 1000px) {
    ${props => props.center ? "text-align:center" : "text-align:left"};
    ${props => props.center ? null : "max-width:600px"};
  }
  @media(min-width:800px){
      font-size:2.2rem;
  }
`
export const IdealMatchText = styled(Text)`
  line-height: 32px;
  margin-top: 45px;
    font-size: 1.8rem;
  font-weight: 700;
    @media(min-width:700px) and (max-width:800px){
        font-size:1.2rem;
    }
    @media(min-width:800px){
        font-size:2.4rem;
    }
  @media(min-width:1200px){
    font-size: 20px;
  }
`
export const OpinionsText = styled(Text)`
  line-height: 32px;
  font-weight: 700;
  font-size:1.3rem;
  margin-top: 35px;
  @media (min-width: 800px) {
    font-size: 22px;
  }
`
export const HeroSearch = styled.input`
    width:235px;
  outline: none;
  height: 100%;
  font-family: var(--fontOther);
  font-weight: 700;
  font-size: 16px;;
  border: none;
  padding: 0 0 0 25px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  @media(min-width:800px) and (max-width:1000px){
      width:33rem;
  }
    @media(max-width:500px){
        width:33rem;
    }
  &::placeholder {
    color: #CDCDCD;
  }
`
export const HeroSearchButton = styled.button`
  border: none;
  background-color: var(--magenta);
  font-family: var(--fontOther);
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 120px;
  font-size: 19px;
  height: 100%;
  outline: none;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
    @media(min-width:800px) and (max-width:1000px){
        width:17rem;
        font-size:2.4rem;
    }
    @media(max-width:500px){
        width:15rem;
        font-size:1.7rem;
    }
`
export const HeroInputContainer = styled.form`
  width: 600px;
  display: flex;
  align-items: flex-start;
  height: 56px;
    @media(max-width:800px){
        width:100%;
    }
`
export const IdealMatch = styled.div`
  width: 100%;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) {
    padding: 150px 25px;
  }
`
export const Button = styled.button`
  font-family: var(--fontOther);
  font-size: 18px;
  font-weight: 600;
  background-color: var(--black);
  border-radius: 100px;
  width: 250px;
  height: 50px;
  margin-top: 30px;
`
export const PinkButton = styled(Button)`
  color: #FFFFFF;
  background-color: var(--magenta);
    border:none;
    outline:none;
`
export const EmptyButton = styled(Button)`
  color: var(--differentBlack);
  background-color: #FFFFFF;
    border:1px solid var(--differentBlack);
`
export const EasyProfessional = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }

`
export const EasyRight = styled.div`
  width: 100%;
  background-color: var(--blue);
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 800px) and (max-width:1200px) {
    width: 50%;
    align-items: flex-start;
    padding: 65px 20px 65px 55px;
  }
    @media(min-width:1200px) {
        width: 50%;
        align-items: flex-start;
        padding: 65px 20px 65px 110px;
    }
`
export const EasyLeft = styled.div`
    display: block;
    width: 50%;
    background-image: url(${background2});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media(max-width:600px){
        width:100%;
        height:100vw;
    }
    @media(min-width:600px) and (max-width:800px){
        width:100%;
        height:80vw;
    }
`
export const Opinions = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Opinion = styled.div`
  width: 100%;
  max-width: 348px;
    height:670px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 80px 25px 50px 25px;
  margin-top: 100px;
`
export const OpinionAvatar = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 100px;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
`
export const OpinionName = styled.span`
  font-size: 20px;
    width:100%;
    text-align: center;
    display:block;
  font-family: var(--fontOther);
  font-weight: 600;
  color: var(--color);
    line-height:45px;
`
export const OpinionText = styled.p`
  font-size: 14px;
  font-family: var(--subFont);
  font-weight: 400;
  color: var(--color);
    line-height:29px;
  text-align: left;
`
