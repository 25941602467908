import {createGlobalStyle} from "styled-components";

export const GlobalStyles = ({ show }) => {
  const GlobalStyle = createGlobalStyle`
    :root {
      --toastify-color-dark: var(--black);
    }

    html {
      font-size: .5787037037vw;
      --yellow: #FDC339;
      --black: #2C2C2C;
      --differentBlack: #2C2C2C;
      --green: #00BF41;
      --lightGray: #F3F4F8;
      --lightGrey: var(--lightGray);
      --gray: rgba(196, 196, 196, 0.63);
      --grey: var(--gray);
      --darkGray: #D9D9D9;
      --magenta:#EE4266;
      --blue:#A8DADC;
      --darkGrey: var(--darkGray);
      --maxWidth: 1200px;
      --fontOther: 'Montserrat', sans-serif;
      --subFont:'Merriweather', sans-serif;
      box-sizing: border-box;
    }
    @media(max-width:1439px){
      html{
        font-size:.6944444444vw;
      }
    }
    @media(max-width:800px){
      html{
        font-size:2.6666666667vw;
      }
    }

    .slick-slider{
      div{
        display:flex;
        justify-content:center;
      }
    }
    *, *:before, *:after {
      box-sizing: inherit;
    }
    button {
      cursor: pointer;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    body {
      font-family: 'Roboto', sans-serif;
      padding: 0;
      margin: 0;
      position: relative;
      font-size: 1.6rem;
      line-height: 1.5;
      overflow-y:${show ? 'hidden' : 'scroll'};
      overflow-x:hidden;
    }

    a {
      text-decoration: none;
      color: var(--black);
    }

    ul, ol, li {
      list-style: none;
    }
  `

  return <GlobalStyle />;
};
