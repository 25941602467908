import React, { useState } from 'react';
import {
    AboutHeader,
    AboutText, BackToMain,
    Container,
    Input,
    InputLabel,
    InputsContainer,
    LoginButton,
    LoginHeader,
    LoginSubHeader,
    RegisterPage,
    ShortContainer,
    ShortestInput,
    ShortInput,
    ShortInputsContainer,
    SuccessContainer, SuccessSubHeader
} from './Register.styles';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Highlight, Text} from "../Login/Login.styles";
import {ReactComponent as Tick} from "../../assets/bigtick.svg";
const Register = () => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [userDetails,setUserDetails]=useState({
        email:"",
        password:"",
    })
    const [photographDetails,setPhotographDetails]=useState({
        first_name:"",
        last_name:"",
        email:"",
        password:"",
        repeat:"",
        company:"",
        address:"",
        postalcode:"",
        city:"",
        nip:""
    })
    const [userErrors,setUserErrors] = useState({
        email:"",
        password:"",
    })
    const [photographErrors,setPhotographErrors] = useState({
        email:"",
        password:"",
        repeat:"",
        company:"",
        address:"",
        postalcode:"",
        city:"",
        nip:""
    })
    const handleUpdateUserDetails = (e) =>{
        setUserDetails(inputs => ({...inputs, [e.target.name] : e.target.value}))
    }
    const handleUpdatePhotographDetails = (e) =>{
        setPhotographDetails(inputs => ({...inputs, [e.target.name] : e.target.value}))
    }
    const handleRegisterUser = async (e) => {
        e.preventDefault()
            const userToLogin = {
                user:{
                    email:userDetails.email,
                    password:userDetails.password
                }
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(userToLogin)
            };
            await fetch(`${process.env.REACT_APP_API_URL}/api/users/user-profile/`,requestOptions)
                .then(response => {
                    if(response.ok){
                        setSuccess(true);
                    }
                })
                .catch(err=>console.log(err))
    }
    const handleRegisterPhotograph = async (e) =>{
        e.preventDefault()
        if(photographDetails.repeat=== photographDetails.password){
            const userToLogin = {
                first_name:photographDetails.first_name,
                last_name:photographDetails.last_name,
                user:{
                    email:photographDetails.email,
                    password:photographDetails.password
                },
                address: photographDetails.address,
                company_name: photographDetails.company,
                nip: photographDetails.nip,
                city: photographDetails.city,
                postcode: photographDetails.postalcode,
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(userToLogin)
            };
            await fetch(`${process.env.REACT_APP_API_URL}/api/users/photographer-profile/`,requestOptions)
                .then(response => {
                    if(response.ok){
                        toast.success('Rejestracja przebiegła pomyślnie! Możesz się zalogować',{
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        })
                        navigate(`/login`);
                    }
                })
                .catch(err=>console.log(err))
        }else{
            const temp = userErrors;
            temp.repeat = "Hasła nie są identyczne"
            setUserErrors(temp);
        }
    }
    return (
        <RegisterPage>
            <Container>
                {!success ? <InputsContainer onSubmit={handleRegisterUser}>
                    <LoginHeader>
                        Cześć
                    </LoginHeader>
                    <LoginSubHeader>
                        Jest nam bardzo miło Cię powitać!
                    </LoginSubHeader>
                    <InputLabel htmlFor="">Email:</InputLabel>
                    <Input type="email" name="email" value={userDetails.email} onChange={handleUpdateUserDetails} required/>
                    <InputLabel htmlFor="">Hasło:</InputLabel>
                    <Input type="password" name="password" value={userDetails.password} onChange={handleUpdateUserDetails} required/>
                    <LoginButton type="submit">Nowe konto</LoginButton>
                    <Text>Masz już swój Folder? <Highlight to={"/login"}>Zaloguj się</Highlight> </Text>
                </InputsContainer> : <SuccessContainer>
                    <Tick style={{marginBottom:'50px'}}/>
                    <LoginHeader>
                        Dziękujemy!
                    </LoginHeader>
                    <SuccessSubHeader>
                        Wysłaliśmy link aktywacyjny na adres email, który podałeś. Kliknij go i aktywuj swoje konto.
                    </SuccessSubHeader>
                    <BackToMain to={"/"}>Powrót do strony głównej.</BackToMain>
                </SuccessContainer>}
            </Container>
        </RegisterPage>
    );
};

export default Register;
