import styled from "styled-components";

export const Cart = styled.main`
  width:100vw;
  min-height:calc(100vh - 70px);
  @media(max-width:1000px){
    min-height:100vh;
  }
`
export const Container = styled.div`
  max-width:1200px;
  margin:0 auto;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  @media(max-width:1220px){
    padding:0 15px;
    margin-bottom:30px;
  }
`
export const Header = styled.h1`
  font-size:20px;
  font-family:var(--fontOther);
  font-weight:700;
  color:var(--black);
  line-height:100%;
  width:100%;
  text-align:left;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding:40px 0;
`
export const CartContainer = styled.div`
  width:100%;
  display:flex;
  align-items: flex-start;
  justify-content:space-between;
  @media(max-width:1000px){
    flex-direction:column;
  }
`
export const Summary = styled.div`
  width:350px;
  height:auto;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  padding:20px 30px;
  border:1px solid var(--black);
  border-radius:10px;
  @media(max-width:1000px){
    width:100%;
  }
`
export const DiffSummary = styled(Summary)`
  width:100%;
  border:none;
  padding:20px 0;
`
export const Items = styled.div`
  width:600px;
  height:auto;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  @media(max-width:1000px){
    width:100%;
    margin-bottom:20px;
  }
`
export const TextOne = styled.h2`
  font-size:22px;
  color:var(--black);
  font-family:var(--fontOther);
  font-weight:700;
  text-align:left;
  width:100%;
  margin:0;
`
export const TextTwo = styled.h3`
  color:var(--black);
  font-family:var(--fontOther);
  font-size:16px;
  font-weight:600;
  margin:0;
`
export const TextThree = styled.h3`
  font-size:22px;
  color:var(--black);
  font-family:var(--fontOther);
  font-weight:700;
  margin:0;
`
export const TextFour = styled.h5`
  width:100%;
  text-align: left;
  color:var(--black);
  font-family:var(--fontOther);
  font-size:14px;
  font-weight:500;
  margin:0;
  padding:0;
`
export const TextContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:10px 0;
`
export const Line = styled.div`
  width:100%;
  height:1px;
  background-color:#E9E9E9;
  margin:10px 0;
`
export const Button = styled.button`
  width:100%;
  height:35px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:0 20px;
  background-color:var(--magenta);
  margin-top:20px;
  border:none;
  outline:none;
  border-radius:100px;
  color:#fff;
  font-family:var(--fontOther);
  font-size:14px;
  font-weight:600;
  line-height:45px;
`
export const Invisible = styled.div`
  width:10px;
  height:10px;
`
export const Item = styled.div`
  width:100%;
  padding:15px 0;
  height:170px;
  border-bottom:1px solid #E9E9E9;
  display:flex;
  justify-content:space-between;
  align-items: flex-start;
  @media(max-width:470px){
    flex-direction:column;
    height:250px;
  }
`
export const ItemImage = styled.div`
  height:140px;
  width:140px;
  background-color:${props => props.image  ? "#fff" : "#E9E9E9"};;
  background-image:${props => props.image  ? `url(${props.image})` : null};
  background-repeat:no-repeat;
  background-size: contain;
  background-position:center;
`
export const ItemDetails = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  padding-left:10px;
  @media(max-width:470px){
    justify-content:center;
  }
`
export const DetailsTitle = styled.h2`
  color:var(--black);
  font-family:var(--fontOther);
  font-size:14px;
  font-weight:600;
`
export const DetailsSubHeader = styled.h3`
  color:var(--black);
  font-family:var(--fontOther);
  font-size:12px;
  font-weight:400;
  margin-bottom:50px;
  margin-top:0;
  @media(max-width:470px){
    margin-bottom:5px;
  }
`
export const ItemDelete = styled.button`
  border:none;
  outline:none;
  background:transparent;
  color: #848484;
  font-family: var(--fontOther);
  font-size: 10px;
  font-weight: 500;
  display:flex;
  align-items: center;
  justify-content:space-between;
  width:60px;
`
// export const ItemEdit
export const ItemLeftSide = styled.div`
  display:flex;
  @media(max-width:470px){
    width:100%;
    justify-content:space-between;
  }
`
export const ItemRightSide = styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  justify-content:space-between;
  height:100%;
  @media(max-width:470px){
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:100%;
  }
`
export const Quantity = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:30px;
`
export const Price = styled.h3`
  margin:0;
  color: #282828;
  font-family: var(--fontOther);
  font-size: 20px;
  font-weight: 600;
`
export const Minus = styled.div`
  width:40px;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  border: 1px solid #848484;
  border-radius: 5px 0px 0px 5px;
  font-family:var(--fontOther);
  font-size:22px;
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
  &:hover{
    background: rgba(168, 218, 220, 0.20);
  }
`
export const Plus = styled.div`
  width:40px;
  height:100%;
  display:flex;
  font-size:22px;
  justify-content:center;
  align-items:center;
  border-radius: 0 5px 5px 0;
  border: 1px solid #848484;
  font-family:var(--fontOther);
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover{
    background: rgba(168, 218, 220, 0.20);
  }
`
export const QuantityText = styled.span`
  display:flex;
  width:40px;
  height:100%;
  justify-content:center;
  align-items:center;
  font-size:14px;
  border-top: 1px solid #848484;
  font-family:var(--fontOther);
  border-bottom: 1px solid #848484;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
export const ShippingContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-top:50px;
`
export const BackContainer = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:center;
  padding:10px 0;
  width:100%;
`
export const BackButton = styled.button`
  color: #282828;
  font-family: var(--fontOther);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 15px */
  display:flex;
  align-items:center;
  border:none;
  outline: none;
  background-color:transparent;
`

export const DetailsContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-top:30px;
  align-items:center;
  justify-content:flex-start;
  position:relative;
`
export const Details = styled.form`
  width:350px;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
`
export const DetailsInput = styled.input`
  width:100%;
  height:50px;
  padding:5px 20px;
  border:1px solid #252525;
  border-radius:100px;    
  color:#282828;
  font-family: var(--fontOther);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 281.25% */
  &::placeholder{
    color: #CDCDCD;
    font-family: var(--fontOther);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px; /* 281.25% */
  }
`
export const DetailsTextarea = styled.textarea`
  width:100%;
  border:1px solid #252525;
  border-radius:10px;
  color:#282828;
  font-family: var(--fontOther);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px; /* 281.25% */
  resize:none;
  padding:5px 20px;
  outline:none;
`
export const DetailsLabel = styled.label`
  width:100%;
  text-align: left;
  color: #252525;
  font-family: var(--fontOther);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 214.286% */
  margin-top:20px;
`
export const CheckboxContainer = styled.div`
  //display: inline-block;
  //vertical-align: middle;
  display:flex;
  align-items:center;
`;

// Hidden checkbox, but still accessible
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0; // Zamiast czynić element małym, uczynić go przezroczystym
  position: absolute;
  width: 16px; // Dopasuj do wielkości StyledCheckbox
  height: 16px; // Dopasuj do wielkości StyledCheckbox
  margin: 0;
  padding: 0;
  z-index: 1; // Upewnij się, że jest nad StyledCheckbox
  outline:none;
`;

// Styled checkbox (visible)
export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid #000;
  padding: 1px; /* This will create the space between the border and the inner box */

  /* Inner box that will be visible when checkbox is checked */
  &:after {
    content: '';
    display: ${props => (props.checked ? 'block' : 'none')};
    width: 100%;
    height: 100%;
    background: black;
    border-radius: 2px; /* Adjust as needed to fit the outer border-radius */
  }

  ${HiddenCheckbox}:hover + & {
    background: ${props => (props.checked ? 'black' : '#f0f0f0')};
    &:after {
      display: none;
    }
  }
`;
export const CheckboxesContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  margin:10px 0;
`
export const ShippingOptionsContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
`
export const CheckboxLabel = styled.label`
  color: #282828;
  font-family: var(--fontOther);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  margin-left:10px;
`
export const EmptyContainer = styled.div`
  width:100%;
  height:100%;
  min-height:90vh;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const EmptyCart = styled.span`
  font-family:var(--fontOther);
  font-size:50px;
  font-weight:600;
  color:#E9E9e9;
`
export const HoverCart = styled.div`
  position:absolute;
  top:20px;
  right:0;
  width:350px;
  height:auto;
  display:${props => props.show ? 'flex' : 'none'};
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  border:1px solid #282828;
  border-radius:10px;
  background-color: #FFFFFF;
  z-index:100;
`
export const CartTitle = styled.h1`
  width:100%;
  text-align:left;
  margin:0;
  padding:10px 20px;
  font-size:22px;
  font-weight:600;
  font-family:var(--fontOther);
  color: #282828;
  `
export const CartItems = styled.div`
  width:100%;
  height:${props => props.scroll ? '350px' : 'auto'};
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  ${props => props.scroll ? 'overflow-y:scroll' : null}
`
export const HoverCartItem = styled.div`
  width:100%;
  height:auto;
  padding:10px 20px;
  display:flex;
  border-top:1px solid #E9E9E9;
`
export const HoverItemImage = styled.div`
  height:70px;
  width:70px;
  background-color:${props => props.image  ? "#fff" : "#E9E9E9"};;
  background-image:${props => props.image  ? `url(${props.image})` : null};
  background-repeat:no-repeat;
  background-size: contain;
  background-position:center;
`
export const HoverItemDetails = styled.div`
  width:100%;
  height:70px;
  display:flex;
  flex-direction:column;
  padding-left:10px;
  justify-content: center;
`
export const HoverItemTitle = styled.h2`
  margin:0;
  font-size:16px;
  font-weight:600;
  font-family:var(--fontOther);
  color: #282828;
`
export const QuantityContainer = styled.div`
  width:100%;
    display:flex;
  justify-content:space-between;
  align-items:center;
`
export const HoverText = styled.span`
  font-size:16px;
  font-weight:400;
  font-family:var(--fontOther);
  color: #282828;
`
export const SumText = styled.h3`
    font-size:20px;
    font-weight:600;
    font-family:var(--fontOther);
    color: #282828;
  margin:0;
`
export const HoverTextContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items: center;
`
export const SumContainer = styled.div`
  display: flex;
  width: 100%;
  padding:20px;
  flex-direction: column;
  background-color: #fcf9f9;
  border-radius: 10px;
  border-top: 1px solid #E9E9E9;
`
export const InPostContainer = styled.div`
  position:absolute;
  top:50%;
  left: 50%;
  transform:translate(-50%,-50%);
  width:500px;
  height:700px;
  z-index:1000;
  display:${props => props.show ? "flex" : "none"};
  flex-direction:column;
`
export const CloseInpost = styled.div`
  width:100%;
  background-color: var(--magenta);
  height:auto;
  display:flex;
  justify-content:flex-end;
  align-items:center;
 
`
export const CloseText = styled.span`
  padding:5px;
  color:#fff;
  font-family:var(--fontOther);
  font-weight:400;
  cursor:pointer;
`
