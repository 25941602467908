import styled from 'styled-components'
import { Link } from 'react-router-dom';
import {ReactComponent as Bag} from '../../assets/Bag.svg';
export const Navbar = styled.nav`
  width:100%;
  background-color:#FFFFFF;
  height:70px;
  transition:background-color 0.5s ease;
  box-shadow: ${props => props.home ? "none" : "0px 0px 17px -8px rgba(0, 0, 0, 1)"};
  display:flex;
  justify-content:center;
  align-items:center;
  // z-index:100;
  // top:0;
  // ${props => props.home ? "position:fixed" :"position:absolute"};
  // ${props => props.home ? "top:0" : null};
  @media(max-width:1000px){
    background-color:#FFFFFF;
  }
`
export const NavContainer = styled.div`
  max-width:var(--maxWidth);
  margin:0 auto;
  display:flex;
  width:100%;
  justify-content:space-between;
  align-items:center;
  @media(max-width:1000px){
    padding:0 20px;
    height:100%;
    position:relative;
  }
`
export const Sidebar = styled.div`
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    background-color: #fff;
    transition: right 0.3s;
    padding: 40px 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    z-index:1000;
    &.open {
        right: 0;
    }
`;
export const MenuItem = styled.div`
    margin: 30px 0;
  width:100%;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  font-family:var(--fontOther);
  font-size:2.5rem;
  font-weight:500;
  color:var(--differentBlack);
  @media(max-width:769px){
    font-size:1.2rem;
  }
`;

export const MenuButtonContainer = styled.div`
  margin-bottom: 10px;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
`;
export const MenuButtonFirst = styled.button`
  font-family:var(--fontOther);
  font-size:2.5rem;
  font-weight:400;
  color:var(--differentBlack);
  background-color:#FFFFFF;
  border:1px solid var(--differentBlack);
  border-radius:100px;
  padding:0 25px;
  height:7.5rem;
  @media(max-width:769px){
    font-size:1.2rem;
    height:3.5rem;
  }
`
export const MenuButtonSecond = styled.button`
  font-family:var(--fontOther);
  font-size:2.5rem;
  font-weight:500;
  color:#FFFFFF;
  background-color:var(--differentBlack);
  border-radius:100px;
  padding:0 25px;
  height:7.5rem;
  @media(max-width:769px){
    font-size:1.2rem;
    height:3.5rem;
  }
  
`
export const NavLogo = styled(Link)`
  display:flex;
  justify-content:center;
  align-items:center;
  @media(max-width:1200px){
    margin-left:15px;
  }
`
export const NavLogoText = styled.span`
  font-size:2.4rem;
  text-transform:uppercase;
  font-family:var(--fontOther);
  font-weight:700;
  margin:0 15px;
  color:var(--black);
  @media(max-width:552px){
    display:none;
  }
`
export const Logo = styled.img`
  width:30px;
  height:auto;
`
export const NavOptions = styled.ul`
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0;
  @media(max-width:1000px){
    display:none;
  }
`
export const NavOption = styled.li`
  margin:0 25px;
  font-family:var(--fontOther);
  font-weight:400;
  font-size:1.6rem;
  color:var(--black);
  @media(min-width:1200px){
    font-size:20px;
  }
`
export const NavLogin = styled(Link)`
  color:var(--black);
  font-size:1.6rem;
  font-weight:400;
  font-family:var(--fontOther);
  margin:0 10px;
  @media(max-width:1000px){
    display:none;
  }
  @media(min-width:1200px){
    font-size:20px;
  }
`
export const NavRegister = styled(NavLogin)`
  font-weight:700;
`
export const User = styled(Link)`
  font-size:2rem;
  display:flex;
  justify-content:center;
  align-items:center;
  span{
    font-size:4.5rem;
  }
  @media(max-width:1000px){
    display:none;
  }
  @media(min-width:1200px){
    font-size:20px;
  }
`
export const Arrow = styled.img`
  width:22px;
  margin-left:8px;
  height:auto;
`
export const Hamburger = styled.div`
  width:30px;
  height:20px;
  display:none;
  cursor:pointer;
  margin:0 20px;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  position:relative;
  @media(max-width:1000px){
    display:flex;
}
`
export const HamburgerPartUpper = styled.span`
  width:100%;
  height:3px;
  background-color:var(--differentBlack);
  transform:${props => props.animate ? "rotate(-45deg) translate(0%,400%);" : "rotate(0deg) translate(0%,0%);"}
  transition:transform 0.5s ease;
`
export const HamburgerPartLower = styled.span`
  width:100%;
  height:3px;
  background-color:var(--black);
  transform:${props => props.animate ? "rotate(45deg) translate(0%,-400%);" : "rotate(0deg) translate(0%,0%);"}
  transition:transform 0.5s ease, bottom 0.5s ease;
`
export const HamburgerPart = styled.span`
  width:100%;
  height:3px;
  background-color:var(--black);
  opacity:${props => props.animate ? "0" : "1"};
  transition:opacity 0.5s ease;
`
export const NavInteract=styled.div`
  display:flex;
  align-items:center;
`
export const LogOut = styled.button`
  font-size:1.6rem;
  background-color:var(--black);
  outline:none;
  border:none;
  font-weight:700;
  display:flex;
  justify-content:center;
  align-items:center;
  color:white;
  margin:0 10px;
  font-family:var(--fontOther);
  padding:10px 20px;
  span{
    margin-left:10px;
  }
  @media(max-width:1000px){
    display:none;
  }
  @media(min-width:1200px){
    font-size:20px;
  }
`
export const CheckoutBagContainer = styled(Link)`
  cursor:pointer;
  margin-right:10px;
  display:flex;
  justify-content:center;
  align-items:center;
  width:40px;
  height:40px;
`
export const CheckoutBag = styled.div`
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  &::after{
    position:absolute;
    content:"${props => props.number}";
    top:-100%;
    right:0;
    transform:translate(50%,50%);
    width:20px;
    height:20px;
    display:flex;
    font-size:16px;
    justify-content:center;
    align-items:center;
    text-align: center;
    border-radius:100px;
    line-height:20px;
    padding-left:0.5px;
    background-color:var(--magenta);
    color:#fff;
  }
`

