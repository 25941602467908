import React, { useContext, useEffect, useState } from 'react';
import {
    ChangePassword,
    Container, ButtonEdit,
    MyAccountHeader,
    MyAccountPage, MyData, MyDataHeader, MyDataHeaderContainer, MyDetails, MyDetailsButtons, MyDetailsHeader, MyEmail,
    MyFolder, MyFolderCode,
    MyFolderLink, MyFolderNotFound,
    MyFolders,
    MyFoldersHeader, ButtonAdd, MyAddress, Address, MyAddresses, Loader, LoaderContainer
} from './MyAccount.styles';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../utils/UserContext';
import { handleResponse } from '../../../utils/handleResponse';
import { authHandler } from '../../../utils/authHandler';
import EditModal from "./Modals/EditModal";

const MyAccount = () => {
    const navigate = useNavigate();
    const {user,setUser} = useContext(UserContext);
    const [profile,setProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [editShipping, setEditShipping] = useState(false);
    const [addShipping, setAddShipping] = useState(false);
    const [address,setAddress] = useState(null);
    const handleFetchProfile = async () =>{
        setLoading(true)
        const auth = authHandler(setUser);
        const requestOptions = {
            method:"GET",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/users/user-profile/${user.user_profile_id}/`,requestOptions)
            .then(r => {
                return handleResponse(r,requestOptions,setUser,navigate)
            })
            .then(r=>r.json())
            .then(data => {
                setProfile(data)
                setLoading(false);
            })
            .catch(err=>console.log(err))
    }
    const handleChangeEmail = () =>{
        setShowModal(true);
        setEditEmail(true);
        setEditPassword(false);
        setAddShipping(false);
        setEditShipping(false);
    }
    const handleChangePassword = () =>{
        setShowModal(true);
        setEditEmail(false);
        setEditPassword(true);
        setAddShipping(false);
        setEditShipping(false);
    }
    const handleAddNewAddress = () =>{
        setShowModal(true);
        setEditEmail(false);
        setEditPassword(false);
        setAddShipping(true);
        setEditShipping(false);
    }
    const handleEditAddress = (address) =>{
        setShowModal(true);
        setEditEmail(false);
        setEditPassword(false);
        setAddShipping(false);
        setEditShipping(true);
        setAddress(address)
    }
    useEffect( ()=>{
        if(user === null){
            navigate(`/`)
        }else{
            handleFetchProfile();
        }
    },[])
    return (
        <MyAccountPage>
            <Container>
                {loading
                    ?
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                    :
                    <>
                        {showModal ? <EditModal editEmail={editEmail} editPassword={editPassword} editShipping={editShipping} addShipping={addShipping} setShowModal={setShowModal} address={address} setProfile={setProfile} profile={profile}/> : null}
                        <MyAccountHeader>Moje konto</MyAccountHeader>
                        {profile && profile.folders.length > 0
                            ?
                            <MyFolders>
                                <MyFoldersHeader>Moje foldery</MyFoldersHeader>
                                {profile &&profile.folders.map(folder => {
                                    return (
                                        <MyFolder>
                                            <MyFolderCode>{folder.code}</MyFolderCode>
                                            <MyFolderLink to={{
                                                pathname:'/user/photofolder',
                                            }} state={folder.code}>wyświetl <span className="material-symbols-outlined">arrow_forward</span></MyFolderLink>
                                        </MyFolder>
                                    )
                                })}
                            </MyFolders>
                            :
                            <MyFolders>
                                <MyFoldersHeader>Moje foldery</MyFoldersHeader>
                                <MyFolder>
                                    <MyFolderNotFound>Niestety, nie znaleźliśmy żadnych fotofolderów</MyFolderNotFound>
                                </MyFolder>
                            </MyFolders>
                        }
                        <MyDetails>
                            <MyDetailsHeader>Moje dane</MyDetailsHeader>
                            <MyEmail>{profile && profile.user.email}</MyEmail>
                            <MyDetailsButtons>
                                <ButtonEdit onClick={handleChangeEmail}>edytuj <span className="material-symbols-outlined">edit</span></ButtonEdit>
                                <ChangePassword onClick={handleChangePassword}>zmień hasło <span className="material-symbols-outlined">lock</span></ChangePassword>
                            </MyDetailsButtons>
                        </MyDetails>
                        <MyData>
                            <MyDataHeaderContainer>
                                <MyDataHeader>Dane do wysyłki</MyDataHeader>
                                <ButtonAdd onClick={handleAddNewAddress}>dodaj <span className="material-symbols-outlined">add</span></ButtonAdd>
                            </MyDataHeaderContainer>
                            <MyAddresses>
                            {profile && profile.shipping_details.map(address=>{
                                return(
                                    <div style={{display:'flex', flexDirection:'column', alignItems:"flex-start", margin:'15px'}}>
                                    <MyAddress>
                                        <Address>{address.first_name} {address.last_name}</Address>
                                        <Address>{address.street} {address.street_number}/{address.apartment_number}</Address>
                                        <div style={{display:'flex'}}>
                                            <Address>{address.postcode} {address.city}</Address>
                                        </div>
                                        <Address>tel: +48 789 891 857</Address>
                                    </MyAddress>
                                    <ButtonEdit onClick={()=>handleEditAddress(address)}>edytuj <span className="material-symbols-outlined">edit</span></ButtonEdit>
                                    </div>
                                )
                            })}
                            </MyAddresses>
                        </MyData>
                    </>
                }
            </Container>
        </MyAccountPage>
    );
};

export default MyAccount;