import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoginPage = styled.main`
  width:100%;
  height:calc(100vh - 300px);
  min-height:800px;
  background-color:white;
  @media(max-width:500px){
    min-height:0px;
    height:auto;
  }
`
export const Container = styled.div`
  max-width:1200px;
  height:100%;
  margin:0 auto;
  display:flex;
  justify-content:center;
  align-items:center;
  @media(max-width:500px){
    align-items:flex-start;
    padding-bottom:50px;
  }
`
export const LoginContainer= styled.div`
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  
`
export const LoginHeader = styled.h1`
  font-size:3.6rem;
  font-weight:700;
  margin:0;
  font-family:var(--fontOther);
  color:var(--black);
  @media(max-width:800px){
    font-size:3.2rem;
  }
`
export const LoginSubHeader = styled.h2`
  font-size:1.8rem;
  font-family:var(--subFont);
  font-weight:700;
  width:90%;
  margin:0;
  color:var(--black);
  @media(max-width:800px){
    font-size:1.4rem;
  }
`
export const InputsContainer = styled.form`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  margin-top:30px;
`
export const InputLabel = styled.label`
  font-size:1.4rem;
  font-family:var(--fontOther);
  font-weight:400;
  color:var(--black);
  margin-top:15px;
  width:100%;
  text-align:left;
  @media(max-width:800px){
    font-size:1rem;
  }
`
export const Input = styled.input`
  width:350px;
  outline:none;
  border-radius:100px;
  border:1px solid var(--black);
  font-size:1.6rem;
  font-family:var(--fontOther);
  font-weight:600;
  height:50px;
  padding-left:20px;
  @media(max-width:800px){
    font-size:1.2rem;
    margin-top:10px;
    width:28rem;
    height:3.5rem;
  }
`
export const Forgotten = styled(Link)`
  width:100%;
  text-align:right;
  font-size:1.4rem;
  font-family:var(--fontOther);
  font-weight:400;
  color:var(--black);
  @media(max-width:800px){
    font-size:1rem;
  }
`
export const LoginButton = styled.button`
  background-color:var(--magenta);
  color:white;
  font-size:1.6rem;
  font-weight:600;
  font-family:var(--fontOther);
  outline:none;
  border:none;
  border-radius:100px;
  margin-top:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  height:5.3rem;
  width:100%;
  @media(max-width:800px){
    font-size:1.2rem;
    height:3.3rem;
  }
`
export const Text = styled.span`
  font-size:1.6rem;
  font-family:var(--fontOther);
  font-weight:400;
  text-align:center;
  width:100%;
  color:var(--black);
  margin-top:20px;
  @media(max-width:800px){
    font-size:1.2rem;
  }
`
export const Highlight = styled(Link)`
  font-size:1.6rem;
  font-family:var(--fontOther);
  font-weight:700;
  color:var(--magenta);
  @media(max-width:800px){
    font-size:1.2rem;
  }
`
export const Arrow = styled.img`
  width:22px;
  margin-left:8px;
  height:auto;
`
export const About = styled.div`
  width:400px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
`
export const AboutHeader = styled.h3`
    font-size:2rem;
    color:var(--black);
  margin:5px 0;
  font-weight:700;
  font-family:var(--fontOther);
`
export const AboutText = styled.p`
  margin:5px 0;
    font-size:1.6rem;
    color:var(--black);
    font-weight:400;
`
export const ErrorText = styled(AboutText)`
  color:red;
`
export const Register = styled(Link)`
  font-size:1.6rem;
  font-family:var(--fontOther);
  font-weight:700;
  margin:5px;
  display:flex;
  align-items:center;
  align-self:flex-end;
  justify-content:center;
  padding:10px;
  color:white;
  background-color:var(--black);
`
