import styled from 'styled-components';

export const Panel = styled.main`
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
`
export const PanelNavigation = styled.nav`
  width:100%;
  height:50px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:var(--yellow);
`
export const PanelNavigationContainer = styled.div`
  width:100%;
  max-width:var(--maxWidth);
  display:flex;
  justify-content:flex-start;
  align-items:center;
  height:100%;
`
export const PanelNavigationElement = styled.button`
  outline:none;
  border:none;
  background-color:transparent;
  padding:16px;
  font-weight:700;
  font-size:1.6rem;
  color:var(--black);
  display:flex;
  justify-content:center;
  align-items:center;
  span{
    margin-left:7px;
    font-size:2rem;
  }
`
export const Container = styled.div`
  width:100%;
  max-width:var(--maxWidth);
  margin:0 auto;
`
export const FinalBar = styled.div`
  width:100vw;
  background-color:white;
  border-top:2px solid var(--yellow);
  height:100px;
  z-index:10;
  position:fixed;
  bottom:0;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const BarContainer = styled.div`
  max-width:var(--maxWidth);
  width:100%;
  margin:0 auto;
  display:flex;
  justify-content:flex-end;
  align-items:center;
`
export const Prepare = styled.button`
  outline:none;
  border:none;
  &:focus{
    outline:none;
    border:none;
  }
  background-color:var(--black);
  padding:10px 15px;
  font-size:1.6rem;
  display:flex;
  justify-content:center;
  align-items:center;
  font-weight:700;
  color:white;
  span{
    margin-left:5px;
    font-size:2.5rem;
    font-weight:700;
  }
`
export const FolderName = styled.span`
  margin:0 10px;
  font-size:2rem;
  font-family:(--fontOther);
  color:var(--black);
  font-weight:700;
`
export const Highlight = styled.span`
  margin:0 5px;
  font-size:2rem;
  font-family:(--fontOther);
  color:var(--yellow);
  font-weight:700;
`
export const FolderCode = styled.span`
  margin:0 10px;
  font-size:2rem;
  font-family:(--fontOther);
  color:var(--black);
  font-weight:700;
`
export const PhotosQuantity = styled.span`
  margin:0 10px;
  font-size:2rem;
  font-family:(--fontOther);
  color:var(--black);
  font-weight:700;
`
export const LeftSideText = styled.span`
  margin:5px 0;
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`
export const LeftSideValue = styled.span`
  margin:5px 20px;
  font-size:2rem;
  font-weight:700;
  color:var(--black);
  font-family:(--fontOther);
`
export const ShareFolderButton = styled.button`
  background-color: var(--green);
  width:100%;
  margin-top:30px;
  padding:5px;
  outline:none;
  border:none;
  font-size:1.6rem;
  color:White;
  font-weight:700;
  display:flex;
  justify-content:center;
  align-items:center;
  span{
    font-size:2.5rem;
    color:white;
    margin-left:10px;
  }
`
export const ShareModal = styled.div`
  position: fixed;
  top:20%;
  right:50%;
  background-color:white;
  transform:translate(50%,20%);
  z-index:11;
  border-top:2px solid var(--yellow);
  width:900px;
  height:auto;
  display:flex;
  flex-direction:column;
  padding:0 65px;
`
export const Close = styled.span`
  width:100%;
  display:flex;
  cursor:pointer;
  justify-content:flex-end;
  padding:15px 0;
  align-items:center;
  span{
    font-size:3rem;
    font-weight:700;
    color:var(--yellow);
  }
`
export const ModalHeader = styled.h1`
  margin:15px 0;
  font-size:4rem;
  font-weight:700;
  font-family:(--fontOther);
  color:var(--black);
`
export const ModalProjectName = styled.span`
  font-size:2rem;
  font-family:var(--fontOther);
  font-weight:700;
  color:var(--black);
  width:250px;
`
export const ModalButton = styled.button`
  font-size:1.6rem;
  width:160px;
  font-weight:700;
  color:white;
  display:flex;
  align-items:center;
  justify-content:space-between;
  outline:none;
  border:none;
  background-color:var(--green);
  span.button-text{
    padding:10px 35px;
  }
  span.material-symbols-outlined{
    font-size:2.3rem;
  }
`
export const ModalInput = styled.input`
  height:50px;
  width:250px;
  border:none;
  display:flex;
  padding:0 10px;
  justify-content:center;
  align-items:center;
  outline:none;
`
export const DisabledModalInput = styled(ModalInput)`
  background-color:var(--grey);
  font-size:2rem;
  font-weight:700;
  font-family:var(--fontOther);
  color:var(--black);
`
export const ActiveModalInput = styled(ModalInput)`
  background-color:var(--lightGrey);
  font-size:1.6rem;
  font-weight:400;
  color:var(--black);
`