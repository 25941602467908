import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AddFolderContainer,
    AddFolderHeader,
    AddOverridingFolderContainer,
    AddOverridingHeader,
    ChooseProjectButton,
    ChooseProjectContainer,
    ChooseProjectHeader,
    ChooseProjectPlaceholder,
    Container,
    Dropzone, DropzoneArea,
    DropzoneImage,
    DropzoneInvisible,
    DropzoneText, FileActionButton, FileActionHeader, FilesAction, FilesActions, FilesActionsHeader,
    FirstVerticalDots,
    FolderNameText,
    FolderPath,
    HorizontalDots,
    InactiveDropzone,
    InputField,
    LabelsContainer,
    LeftSide,
    OverridingFolderAdd,
    OverridingFolderInput,
    PhotosLabel,
    PlaceholderContainer,
    PopulatedDropzone,
    PopulatedInputsLabel,
    ProjectNameContainer,
    RightSide,
    SecondVerticalDots,
    StructureHeader,
    UploadButton
} from "./AddFolder.styles";
import {authHandler} from "../../../../utils/authHandler";
import {handleResponse} from "../../../../utils/handleResponse";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../../../utils/UserContext";
import cloud from "../../../../assets/cloud.png"
import Select from "react-select";
import flaga from "../../../../assets/auto_awesome.png"
import {toast, ToastContainer} from "react-toastify";
import PhotoItem from "./PhotoItem/PhotoItem";

const firstStyles = {
    control: (base, state) => ({
        ...base,
        border: 'none',
        borderRadius: '0',
        outline: state.isFocused ? 'none' : 'none',
        backgroundColor: `var(--lightGrey)`,
        width: '320px'
    }),
    option: (base, state) => ({
        ...base,
        color: 'black',
        background: state.isFocused ? 'var(--yellow)' : 'var(--lightGrey)'
    }),
}
const secondStyles = {
    container: (base) => ({
        ...base,
        width: '100%'
    }),
    control: (base, state) => ({
        ...base,
        border: 'none',
        borderRadius: '0',
        outline: state.isFocused ? 'none' : 'none',
        width: '100%'
    }),
    option: (base, state) => ({
        ...base,
        color: 'black',
        background: state.isFocused ? 'var(--yellow)' : 'white'
    }),
}
const thirdStyles = {
    container: (base) => ({
        ...base,
        width: '50%'
    }),
    control: (base, state) => ({
        ...base,
        border: 'none',
        borderRadius: '0',
        outline: state.isFocused ? 'none' : 'none',
        backgroundColor: `var(--lightGrey)`,
        width: '100%'
    }),
    option: (base, state) => ({
        ...base,
        color: 'black',
        background: state.isFocused ? 'var(--yellow)' : 'white'
    }),
}

const AddFolder = () => {
    const navigate = useNavigate();
    const {user, setUser, setFolder} = useContext(UserContext);
    const fetchPhotographerProjects = async () => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method: "GET",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/photos/project/`, requestOptions)
            .then(response => handleResponse(response, requestOptions, setUser, navigate))
            .then(response => response.json())
            .then(data => setProjectsData(data))
    }
    const [chosenProject, setChosenProject] = useState({});
    const [selectValue, setSelectValue] = useState();
    const [projectsData, setProjectsData] = useState([]);
    const [overridingFolders, setOverridingFolders] = useState([])
    const [moveFoldersOptions, setMoveFoldersOptions] = useState([]);
    const [activeFolder, setActiveFolder] = useState(0);
    const [activeFoldersInners, setActiveFoldersInners] = useState([]);
    const [overridingFolder, setOverridingFolder] = useState({
        value: 0,
        label: 'Wybierz projekt'
    })
    const [activeMoveFolder, setActiveMoveFolder] = useState({})
    const [newFolder, setNewFolder] = useState("")
    const [folderPath, setFolderPath] = useState("")
    const [dragActive, setDragActive] = useState(false);
    const [chosenPhotos, setChosenPhotos] = useState([]);
    const [foldersDepth, setFoldersDepth] = useState(1);
    const [addFolderError,setAddFolderError] = useState("")
    const handleChooseOverridingFolder = (option) => {
        setOverridingFolder(option)
        setAddFolderError("")
    }
    const handleChangeActiveMoveFolder = (option) => {
        setActiveMoveFolder(option)
    }
    const handleChooseProject = (option) => {
        setOverridingFolder()
        setSelectValue(option)
        if (option !== null) {
            const temp = projectsData.filter(project => project.id.toString() === option.value)
            setChosenProject(temp[0])
        }
        setFolderPath(`${option.label}`);
        setOverridingFolder({
            value: 0,
            label: 'Wybierz projekt'
        });
        setNewFolder("");
        setActiveFolder(0)
        let temp = moveFoldersOptions
        let temp2 = projectsData.filter(project => project.id.toString() === option.value)
        temp2[0].folders.map(folder => {
            temp.push({
                value: folder.id,
                label: folder.name
            })
            folder.children.map(subFolder => {
                temp.push({
                    value: subFolder.id,
                    label: subFolder.name
                })
                subFolder.children.map(subsubFolder => {
                    temp.push({
                        value: subsubFolder.id,
                        label: subsubFolder.name
                    })
                })
            })
        })
        setMoveFoldersOptions(temp)
        let temp3 = overridingFolders
        let temp4 = projectsData.filter(project => project.id.toString() === option.value)
        temp4[0].folders.map(folder => {
            temp3.push({
                value: folder.id,
                label: folder.name
            })
            folder.children.map(subFolder => {
                temp3.push({
                    value: subFolder.id,
                    label: subFolder.name
                })
            })
        })
        setOverridingFolders(temp3);
    }
    const handleChangeActiveFolder = (folder, path, depth) => {
        setActiveFolder(folder)
        setFolderPath(path)
        setChosenPhotos([]);
        setFoldersDepth(depth)
        handleFetchActiveFolder(folder)
    }
    const handleFetchActiveFolder = async (folder) => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method: "GET",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/${folder.id}/`, requestOptions)
            .then(response => handleResponse(response, requestOptions, setUser, navigate))
            .then(response => response.json())
            .then(data => {
                setActiveFoldersInners(data.folder_photos)
                if(data.children.length === 0){
                    setFolder(data)
                }else{
                    setFolder(null);
                }
            })
    }
    const handleAddNewFolder = async () => {
        if (overridingFolder.value !== 0 && newFolder.length > 3) {
            const auth = authHandler(setUser);
            const requestOptions = {
                method: "POST",
                headers: {'Content-Type': 'application/json', 'Authorization': auth},
                body: JSON.stringify({
                    name: newFolder,
                    project: chosenProject.id,
                    parent: overridingFolder.value.project ? null : overridingFolder.value
                })
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => response.json())
                .then(data => {
                    setChosenProject(data)
                })
        } else if (overridingFolder.value === 0) {
            setAddFolderError("Wybierz folder nadrzędny")
        } else {
            setAddFolderError("Wpisz nazwę folderu")
        }
    }
    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
    const traverseFileTree = (item, path, formData) => {
        return new Promise((resolve) => {
            path = path || "";
            if (item.isFile) {
                item.file((file) => {
                    formData.append(
                        "file",
                        file,
                        file.webkitRelativePath === ""
                            ? file.name
                            : file.webkitRelativePath.replace(/\//g, "@")
                    );
                    resolve();
                });
            } else if (item.isDirectory) {
                let dirReader = item.createReader();
                dirReader.readEntries(async (entries) => {
                    for (let i = 0; i < entries.length; i++) {
                        await traverseFileTree(entries[i], path + item.name + "@", formData);
                    }
                    resolve();
                });
            }
        });
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        let formData = new FormData();
        let items = Array.from(e.dataTransfer.items);
        // create an array of promises that resolve when traverseFileTree is done
        const promises = items.map(async item => {
            await traverseFileTree(item.webkitGetAsEntry(), "", formData);
        });
        // wait for all promises to resolve
        await Promise.all(promises);

        // rest of the code
            if (Array.from(formData.get("file").name).filter(el => el === "@").length + foldersDepth > 4) {
                toast.error("Projekt może mieć maksymalnie 3 podfoldery!");
                return null;
            } else {
                const auth = authHandler(setUser);
                const requestOptions = {
                    method: "POST",
                    headers: {'Authorization': auth},
                    body: formData
                }
                await fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/${activeFolder.id}/upload/`, requestOptions)
                    .then(response => handleResponse(response, requestOptions, setUser, navigate))
                    .then(response => {
                        if (response.ok) {
                            handleFetchActiveFolder(activeFolder)
                            return response.json()
                        }
                    })
                    .then(data => setChosenProject(data.project))
            }
    }
    const handleChange = async (e) => {
        e.preventDefault();
        const uploadFileElement = e.target.files;
        let files = Array.from(uploadFileElement);
        let formData = new FormData();
        let check = false;
        files.forEach((file) => {
            if (Array.from(file.webkitRelativePath).filter(el => el === "/").length + foldersDepth > 4) {
                check = true;
            } else {
                formData.append("file", file, file.webkitRelativePath === "" ? file.name : file.webkitRelativePath.replace(/\//g, "@"));
            }
        });
        if (check) {
            toast.error("Projekt może mieć maksymalnie 3 podfoldery!");
            return null
        } else {
            const auth = authHandler(setUser);
            const requestOptions = {
                method: "POST",
                headers: {'Authorization': auth},
                body: formData
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/${activeFolder.id}/upload/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => {
                    if (response.ok) {
                        handleFetchActiveFolder(activeFolder)
                        return response.json()
                    }
                })
                .then(data => setChosenProject(data))
        }
    }
    const handleChoosePhoto = (checked, photo) => {
        if (checked) {
            setChosenPhotos(prevState => [...prevState, photo.photo.id])
        } else {
            setChosenPhotos(prevState => prevState.filter(el => el !== photo.photo.id))
        }
    }
    const handleMoveFiles = async (e) => {
        e.preventDefault();
        if (activeMoveFolder !== {}) {
            const auth = authHandler(setUser);
            const requestOptions = {
                method: "POST",
                headers: {'Content-Type': 'application/json', 'Authorization': auth},
                body: JSON.stringify({
                    photos: chosenPhotos,
                    source_folder: activeFolder.id,
                    destination_folder: activeMoveFolder.value
                })
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photos/move-photos/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => {
                    if (response.ok) {
                        handleFetchActiveFolder(activeFolder)
                        setChosenPhotos([]);
                    }
                })
        }
    }
    const handleFlagPhoto = async (e, flag) => {
        e.preventDefault();
        let flags = {}
        if (flag === "redone") {
            flags = {
                is_processed: true
            }
        } else {
            flags = {
                is_highlighted: true
            }
        }
        const auth = authHandler(setUser);
        if (chosenPhotos.length > 0) {
            for (const photo of chosenPhotos) {
                const index = chosenPhotos.indexOf(photo);
                const requestOptions = {
                    method: "PATCH",
                    headers: {'Content-Type': 'application/json', 'Authorization': auth},
                    body: JSON.stringify(flags)
                }
                await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photo/${photo}/`, requestOptions)
                    .then(response => handleResponse(response, requestOptions, setUser, navigate))
                console.log("PATCH");
                if (photo === chosenPhotos[chosenPhotos.length - 1]) {
                    console.log("GET")
                    await handleFetchActiveFolder(activeFolder)
                    setChosenPhotos([]);
                }
            }
        }
    }
    const handleDeletePhoto = async (id) => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method: "DELETE",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photo/${id}/`, requestOptions)
            .then(response => handleResponse(response, requestOptions, setUser, navigate))
            .then(response => {
                if (response.ok) {
                    handleFetchActiveFolder(activeFolder)
                }
            })
    }

    useEffect(() => {
        fetchPhotographerProjects()
    }, [])
    return (
        <AddFolderContainer>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <AddFolderHeader>Dodaj zdjęcia</AddFolderHeader>
            <ChooseProjectContainer>
                <ChooseProjectHeader>Wybierz Projekt/Klienta:</ChooseProjectHeader>
                <Select
                    value={selectValue}
                    onChange={handleChooseProject}
                    placeholder='Wybierz projekt'
                    styles={firstStyles}
                    isSearchable isClearable name="projects" options={projectsData.map(project => {
                    return (
                        {
                            value: `${project.id}`,
                            label: `${project.name}`
                        }
                    )
                })}/>
                <ChooseProjectButton>Nowy klient <span
                    className="material-symbols-outlined">add</span></ChooseProjectButton>
            </ChooseProjectContainer>
            <Container>
                <div style={{width: '100%', display: 'flex'}}>
                    <LeftSide>
                        {selectValue && (
                            <>
                                <StructureHeader>Struktura Fotofolderu</StructureHeader>
                                <ProjectNameContainer><span
                                    className="material-symbols-outlined">folder</span><FolderNameText>{chosenProject.name}</FolderNameText></ProjectNameContainer>
                                <FirstVerticalDots style={{
                                    marginLeft: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    borderLeft: '1px dotted black'
                                }}>
                                    {chosenProject.folders && chosenProject.folders.length > 0 ? chosenProject.folders.map(ele => (
                                        <>
                                            <ProjectNameContainer
                                                onClick={() => handleChangeActiveFolder(ele, `${chosenProject.name} / ${ele.name}`, 2)}><HorizontalDots/><span
                                                className="material-symbols-outlined">folder</span><FolderNameText
                                                style={activeFolder.id === ele.id ? {
                                                    backgroundColor: `var(--black)`,
                                                    color: 'white'
                                                } : null}>{ele.name}</FolderNameText></ProjectNameContainer>
                                            <SecondVerticalDots>
                                                {ele.children && ele.children.length > 0 ? ele.children.map(project => (
                                                    <>
                                                        <ProjectNameContainer
                                                            onClick={() => handleChangeActiveFolder(project, `${chosenProject.name} / ${ele.name} / ${project.name}`, 3)}><HorizontalDots/><span
                                                            className="material-symbols-outlined">folder</span><FolderNameText
                                                            style={activeFolder.id === project.id ? {
                                                                backgroundColor: `var(--black)`,
                                                                color: 'white'
                                                            } : null}>{project.name}</FolderNameText></ProjectNameContainer>
                                                        <SecondVerticalDots>
                                                            {project.children && project.children.length > 0 ? project.children.map(subFolder => (
                                                                <ProjectNameContainer
                                                                    onClick={() => handleChangeActiveFolder(subFolder, `${chosenProject.name} / ${ele.name} / ${project.name} / ${subFolder.name}`, 4)}><HorizontalDots/><span
                                                                    className="material-symbols-outlined">folder</span><FolderNameText
                                                                    style={activeFolder.id === subFolder.id ? {
                                                                        backgroundColor: `var(--black)`,
                                                                        color: 'white'
                                                                    } : null}>{subFolder.name}</FolderNameText></ProjectNameContainer>
                                                            )) : null}
                                                        </SecondVerticalDots>
                                                    </>
                                                )) : null}
                                            </SecondVerticalDots>
                                        </>
                                    )) : null}
                                </FirstVerticalDots>
                                <AddOverridingFolderContainer>
                                    <AddOverridingHeader>Utwórz folder</AddOverridingHeader>
                                    <OverridingFolderInput type="text" placeholder="Nazwa" value={newFolder}
                                                           onChange={e => {setNewFolder(e.target.value);setAddFolderError("")}}
                                                           webkitdirectory/>
                                    <Select
                                        value={overridingFolder}
                                        onChange={handleChooseOverridingFolder}
                                        placeholder="Wybierz projekt"
                                        styles={secondStyles}
                                        name="folders"
                                        options={[
                                        {
                                            label: "Projekt",
                                            options: [{
                                                value: {id: chosenProject.id, project: true},
                                                label: chosenProject.name
                                            }]
                                        },
                                        {
                                            label: "Foldery",
                                            options: overridingFolders
                                        },
                                    ]}
                                    />
                                    <OverridingFolderAdd onClick={handleAddNewFolder}>Utwórz <span
                                        className="material-symbols-outlined">create_new_folder</span>
                                    </OverridingFolderAdd>
                                    {addFolderError.length > 0 ? <span style={{color:'red'}}>{addFolderError}</span> : null}
                                </AddOverridingFolderContainer>
                            </>
                        )}
                        {!selectValue && (
                            <PlaceholderContainer>
                                <ChooseProjectPlaceholder>
                                    Wybierz projekt
                                </ChooseProjectPlaceholder>
                            </PlaceholderContainer>
                        )}
                    </LeftSide>
                    <RightSide>
                        <FolderPath>{folderPath}</FolderPath>
                        {activeFolder ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: '10px'
                            }}>
                                <InputField type="file" id="upload-files" multiple onChange={handleChange}/>
                                <UploadButton htmlFor="upload-files">Wyślij pliki <span
                                    className="material-symbols-outlined">upload_file</span></UploadButton>
                                {foldersDepth === 4 ? null : (
                                    <>
                                        <input style={{display: 'none'}} directory="" webkitdirectory="" type="file"
                                               id="upload-directories" onChange={handleChange}/>
                                        <UploadButton htmlFor="upload-directories">Wyślij folder <span
                                            className="material-symbols-outlined">drive_folder_upload</span></UploadButton>
                                    </>
                                )}
                            </div>
                        ) : null}
                        {activeFolder ? activeFoldersInners.length > 0
                            ? (
                                <PopulatedDropzone onDragEnter={handleDrag} directory="" webkitdirectory="" multiple>
                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                        <input type="checkbox" id="check-all"/>
                                        <label htmlFor="check-all">Zaznacz wszystkie</label>
                                    </div>
                                    <PopulatedInputsLabel>
                                        <LabelsContainer>
                                            <span style={{width: '5%', height: '5px'}}></span>
                                            <PhotosLabel style={{width: '15%'}}>Zdjęcie</PhotosLabel>
                                            <PhotosLabel style={{width: '20%'}}>Nazwa</PhotosLabel>
                                            <PhotosLabel style={{width: '10%'}}>Flaga</PhotosLabel>
                                            <PhotosLabel style={{width: '15%'}}>Data</PhotosLabel>
                                            <PhotosLabel style={{width: '10%'}}>Pobrań</PhotosLabel>
                                            <PhotosLabel style={{width: '15%'}}>Rozmiar</PhotosLabel>
                                            <PhotosLabel style={{width: '5%', height: '1px'}}></PhotosLabel>
                                            <PhotosLabel style={{width: '5%', height: '1px'}}></PhotosLabel>
                                        </LabelsContainer>
                                        {activeFoldersInners.map(photo => (
                                            <PhotoItem photo={photo} chosenPhotos={chosenPhotos} handleDeletePhoto={handleDeletePhoto} handleChoosePhoto={handleChoosePhoto}/>
                                        ))}
                                    </PopulatedInputsLabel>
                                    {dragActive && <DropzoneArea id="drag-file-element" onDragEnter={handleDrag}
                                                                 onDragLeave={handleDrag} onDragOver={handleDrag}
                                                                 onDrop={handleDrop} directory="" webkitdirectory=""
                                                                 multiple>
                                        <DropzoneImage src={cloud}/>
                                        <DropzoneText>Upuść pliki tutaj</DropzoneText>
                                    </DropzoneArea>}
                                </PopulatedDropzone>
                            ) : (
                                <Dropzone onDragEnter={handleDrag} style={dragActive ? {opacity: '0.6'} : null}>
                                    <DropzoneImage src={cloud}/>
                                    <DropzoneText>Upuść pliki tutaj</DropzoneText>
                                    {dragActive && <DropzoneInvisible id="drag-file-element" onDragEnter={handleDrag}
                                                                      onDragLeave={handleDrag} onDragOver={handleDrag}
                                                                      onDrop={handleDrop}></DropzoneInvisible>}
                                </Dropzone>
                            ) : (
                            <InactiveDropzone>
                                <ChooseProjectPlaceholder>
                                    Wybierz folder do którego chcesz przesłać pliki.
                                </ChooseProjectPlaceholder>
                            </InactiveDropzone>
                        )}
                        <FilesActions>
                            {activeFoldersInners.length > 0 && (
                                <>
                                    <FilesActionsHeader>Działania na plikach</FilesActionsHeader>
                                    <FilesAction>
                                        <FileActionHeader>Przenieś do folderu</FileActionHeader>
                                        <Select placeholder='Wybierz folder'
                                                styles={thirdStyles} name="folders" options={moveFoldersOptions}
                                                value={activeMoveFolder} onChange={handleChangeActiveMoveFolder}/>
                                        <FileActionButton onClick={handleMoveFiles}>Przenieś <span
                                            className="material-symbols-outlined">drive_folder_upload</span></FileActionButton>
                                    </FilesAction>
                                    <FilesAction>
                                        <FileActionHeader>Oznacz jako (flaga):</FileActionHeader>
                                        <FileActionButton onClick={e=>handleFlagPhoto(e,"redone")}>Retusz <span
                                            className="material-symbols-outlined">auto_fix_high</span></FileActionButton>
                                        <FileActionButton onClick={e=>handleFlagPhoto(e,"popular")}>Polecane <span
                                            className="material-symbols-outlined">trending_up</span></FileActionButton>
                                    </FilesAction>
                                </>
                            )}
                        </FilesActions>
                    </RightSide>
                </div>
            </Container>
        </AddFolderContainer>
    );
};

export default AddFolder;