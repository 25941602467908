import React, {useContext, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
    AddFolderContainer,
    AddFolderHeader,
    Container, FolderNameText,
    FolderPath,
    LabelsContainer,
    LeftSide,
    PhotosLabel,
    PopulatedDropzone,
    PopulatedInputsLabel,
    RightSide, StructureHeader,
} from "../PhotographerPanel/AddFolder/AddFolder.styles";
import PhotoItem from "../PhotographerPanel/AddFolder/PhotoItem/PhotoItem";
import {useQueryClient, useMutation, useQuery} from "react-query";
import {authHandler} from "../../../utils/authHandler";
import {UserContext} from "../../../utils/UserContext";
import {handleResponse} from "../../../utils/handleResponse";
import {
    ActiveModalInput,
    Close, DisabledModalInput,
    LeftSideText,
    LeftSideValue, ModalButton,
    ModalHeader, ModalProjectName,
    ShareFolderButton, ShareModal
} from "../PhotographerPanel/PhotographerPanel.styles";
import * as PropTypes from "prop-types";
import {Modal} from "../../User/MyAccount/Modals/EditModal.styles";
import {toast, ToastContainer} from "react-toastify";


const PreparePhotofolder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient =  useQueryClient();
    const { setUser } = useContext(UserContext);
    const [chosenPhotos, setChosenPhotos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState("");
    const fetchFolder = () => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method: "GET",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        return fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/${location.state}/`, requestOptions)
            .then(response => handleResponse(response, requestOptions, setUser, navigate))
            .then(response => {
                const result = response.json();
                return result;
            })
    }
    const handleSendEmail = async() =>{
        let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (emailRegex.test(email)) {
            const auth = authHandler(setUser);
            const requestOptions = {
                method: "POST",
                headers: {'Content-Type': 'application/json', 'Authorization': auth},
                body:JSON.stringify({
                    emails:[email]
                })
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/photos/folder/${data?.id}/share-folder/`, requestOptions)
                .then(response => handleResponse(response, requestOptions, setUser, navigate))
                .then(response => {
                    if (response.ok) {
                        toast.success("Mail wysłany poprawnie!")
                        setEmail("");
                        setShowModal(false)
                    }
                })
        } else {
            toast.error("Niepoprawny adres email")
        }
    }
    const handleChoosePhoto = (checked, photo) => {
        if (checked) {
            setChosenPhotos(prevState => [...prevState, photo.photo.id])
        } else {
            setChosenPhotos(prevState => prevState.filter(el => el !== photo.photo.id))
        }
    }
    const handleDeletePhoto = async (id) => {
        const auth = authHandler(setUser);
        const requestOptions = {
            method: "DELETE",
            headers: {'Content-Type': 'application/json', 'Authorization': auth},
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/photos/photo/${id}/`, requestOptions)
            .then(response => handleResponse(response, requestOptions, setUser, navigate))
            .then(response => {
                if (response.ok) {
                    // handleFetchActiveFolder(activeFolder)
                }
            })
    }
    const { data, isError, isLoading } = useQuery("folder", fetchFolder, {keepPreviousData: false})
    if(isLoading) {
        return <div>Loading...</div>
    }
    if(isError){
        return <div>Error</div>
    }
    return (
        <AddFolderContainer style={{minHeight:'1000px'}}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {showModal
                ?
                    <ShareModal>
                        <Close><span className="material-symbols-outlined" onClick={()=>setShowModal(false)}>close</span></Close>
                        <ModalHeader>Udostępnij Fotofolder</ModalHeader>
                        <ModalProjectName style={{margin:'15px 0'}}>Projekt: {data?.name}</ModalProjectName>
                        <div style={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                            <ModalProjectName>Wyślij na adres email</ModalProjectName>
                            <ActiveModalInput type="text" value={email} onChange={e => setEmail(e.target.value)} />
                            <ModalButton onClick={handleSendEmail}>
                                <span className="button-text">Wyślij</span>
                                <span className="material-symbols-outlined">send</span>
                            </ModalButton>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center', margin:'20px 0'}}>
                            <ModalProjectName>Skopiuj numer dostępu</ModalProjectName>
                            <DisabledModalInput disabled type="text" value={data?.code}/>
                            <ModalButton onClick={() => {navigator.clipboard.writeText(data?.code);toast.success('Skopiowano!')}}>
                                <span className="button-text">Skopiuj</span>
                                <span className="material-symbols-outlined">file_copy</span>
                            </ModalButton>
                        </div>
                    </ShareModal>
                : null}
            <AddFolderHeader>Przygotuj Fotofolder</AddFolderHeader>
            <Container>
                <div style={{width: '100%', display: 'flex'}}>
                    <RightSide>
                        <FolderPath>Projekt: {data?.name}</FolderPath>
                        {data ? data.folder_photos.length > 0
                            ? (
                                <PopulatedDropzone>
                                    <PopulatedInputsLabel>
                                        <LabelsContainer>
                                            {/*<span style={{width: '5%', height: '5px'}}></span>*/}
                                            <PhotosLabel style={{width: '15%'}}>Zdjęcie</PhotosLabel>
                                            <PhotosLabel style={{width: '20%'}}>Nazwa</PhotosLabel>
                                            <PhotosLabel style={{width: '10%'}}>Flaga</PhotosLabel>
                                            <PhotosLabel style={{width: '15%'}}>Data</PhotosLabel>
                                            <PhotosLabel style={{width: '10%'}}>Pobrań</PhotosLabel>
                                            <PhotosLabel style={{width: '15%'}}>Rozmiar</PhotosLabel>
                                            <PhotosLabel style={{width: '5%', height: '1px'}}></PhotosLabel>
                                            <PhotosLabel style={{width: '5%', height: '1px'}}></PhotosLabel>
                                        </LabelsContainer>
                                        {data.folder_photos.map(photo => (
                                            <PhotoItem photo={photo} key={photo.photo.id} chosenPhotos={chosenPhotos}
                                                       handleDeletePhoto={handleDeletePhoto}
                                                       handleChoosePhoto={handleChoosePhoto} viewFolder={true} />
                                        ))}
                                    </PopulatedInputsLabel>
                                </PopulatedDropzone>
                            ) : null : null}
                    </RightSide>
                    <LeftSide>
                        <StructureHeader>Podsumowanie</StructureHeader>
                        <div style={{width:'100%', display:'flex',justifyContent:'space-between',alignItems: 'center'}}>
                            <LeftSideText>Ilość plików</LeftSideText>
                            <LeftSideValue>{data.folder_photos.length}</LeftSideValue>
                        </div>
                        <LeftSideText>W tym:</LeftSideText>
                        <div style={{width:'100%', display:'flex',flexDirection:'column'}}>
                            <div  style={{width:'100%', display:'flex',justifyContent:'space-between',alignItems: 'center',paddingLeft:'20px'}}>
                                <LeftSideText>Bez retuszu</LeftSideText>
                                <LeftSideValue>{data.folder_photos.filter(photo => !photo.photo.is_processed).length}</LeftSideValue>
                            </div>
                            <div  style={{width:'100%', display:'flex',justifyContent:'space-between',alignItems: 'center',paddingLeft:'20px'}}>
                                <LeftSideText>Po retuszu</LeftSideText>
                                <LeftSideValue>{data.folder_photos.filter(photo => photo.photo.is_processed).length}</LeftSideValue>
                            </div>
                            <div  style={{width:'100%', display:'flex',justifyContent:'space-between',alignItems: 'center',paddingLeft:'20px'}}>
                                <LeftSideText>Wyróżnione</LeftSideText>
                                <LeftSideValue>{data.folder_photos.filter(photo => photo.photo.is_highlighted).length}</LeftSideValue>
                            </div>
                        </div>
                        <ShareFolderButton onClick={()=>setShowModal(true)}>Udostępnij <span className="material-symbols-outlined">ios_share</span></ShareFolderButton>
                    </LeftSide>
                </div>
            </Container>
        </AddFolderContainer>
    );
};

export default PreparePhotofolder;