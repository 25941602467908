import * as authenticationService from './authentication.service';

export async function handleResponse(response, requestOptions, setUser, navigate) {
    if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
             const clonedResponse = response.clone();
            const body = await clonedResponse.json();
            if (body.detail && body.detail.toLowerCase().indexOf('token') !== -1) {
                return authenticationService.refreshToken(response.url, requestOptions, setUser, navigate);
            }
        }
    }
    return response;
}